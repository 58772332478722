export const texasCities = [
    
    {
        value: ``
    },
    {
        value: `New Braunfels`
    },
    {
        value: `Bulverde`
    },
    {
        value: `San Marcos`
    },
    {
        value: `Garden Ridge`
    },
    {
        value: `Houston`
    },
    {
        value: `Canyon Lake`
    }
]

export const counties = [
    {
        city: `New Braunfels`,
        value: `Comal`
    },
    {
        city: `Bulverde`,
        value: `Comal`
    },
    {
        city: `San Marcos`,
        value: `Hays`
    },
    {
        city: `Garden Ridge`,
        value: `Comal`
    },
    {
        city: `Houston`,
        value: `Harris`
    },
    {
        city: `Canyon Lake`,
        value: `Comal`
    }
]

export const content = {
    title: {
        key: 'title',
        value: 'We are happy to serve you'
    },
    mainContent: [
        {
            key: 'para1',
            title: `The Business Property Tax Group has been representing and assisting business owners with their property tax 
            issues for the past 10 years. It has been the only property tax consulting firm that specifically specializes in business 
            personal property taxes. This experience is invaluable for our clients.`
        },
        {
            key: 'para2',
            title: `Our Services Include:`,
            list: [
                {
                    key: 'list1',
                    value: 'Prepare annual business personal property tax renditions'
                },
                {
                    key: 'list2',
                    value: 'Analyze a company’s assets to determine correct taxable valuations'
                },
                {
                    key: 'list3',
                    value: 'Analyze inventory to determine correct taxable values and exemptions'
                },
                {
                    key: 'list4',
                    value: 'File property tax valuation appeals with appraisal districts'
                },
                {
                    key: 'list5',
                    value: 'Attend all hearing before the appraisal district and the Appraisal Review Boards'
                },
                {
                    key: 'list6',
                    value: 'Consulting services'
                },
                {
                    key: 'list7',
                    value: 'Review of yearly tax bills to assure accuracy'
                }
            ]
        },
    ],
}