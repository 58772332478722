import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const countyValues = () => {
    return svcRequest.get(config.codeValues, { params: { codeSetNumber: '6' } })
}
  
export const stateValues = () => {
    return svcRequest.get(config.codeValues, { params: { codeSetNumber: '1' } })
}

export const cityValues = () => {
    return svcRequest.get(config.codeValues, { params: { codeSetNumber: '7' } })
}

export const countyValuesFromCity = (City) => {
    return svcRequest.get(config.codeValues, { params: { codeValueKey: City, codeSetNumber: '7' } })
}