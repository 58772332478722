import NotificationManager from "react-notifications/lib/NotificationManager";
import { push } from 'connected-react-router';
import { setClientDetails } from "../../appClient/AppClientActions";
import { loginVerify,loginVerifyCode } from "../../../api/AuthApi";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_CLIENT_AUTH = 'SET_CLIENT_AUTH';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const login = (loginEmail, loginPwd, userType) => {
    return (dispatch) => {
        const loginPayload = {
            emailId: loginEmail,
            password: loginPwd
        }

        dispatch({ type: LOGIN });
        loginVerify(loginPayload).then((res) => {
            if(res && res.status === 200 && res.data){
                if (res.data.userType === userType || res.data.userType === 'CLIENT') {
                    dispatch({ type: LOGIN_SUCCESS });
                    const userFirstName = res.data.fullName ? res.data.fullName.split(' ')[0] : `Unknown`;
                    const userLastName = res.data.fullName && res.data.fullName.split(' ').length > 1 ? res.data.fullName.split(' ')[1] : ``;
                    res.data.userType && res.data.userType === 'AGENT' ? 
                    dispatch({ type: SET_CLIENT_AUTH, payload: false }) : 
                    dispatch({ type: SET_CLIENT_AUTH, payload: true });
                    dispatch(setClientDetails({firstName: userFirstName, lastName: userLastName, 
                        email: res.data.emailId, userId: res.data.id,userType:res.data.userType}));
                    dispatch(push("/appClientHome"));
                } else {
                    dispatch({ type: LOGIN_ERROR });
                    NotificationManager.error(`Choose a Valid user type for logging in!!!`);
                    dispatch(push("/"));
                }
            } 
        }).catch( err => {
            if(err.response && err.response.status === 422){
                dispatch({type: LOGIN_ERROR, payload: err.response.data.errors});
                NotificationManager.error(`Invalid request to login!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 403 ){
                dispatch({type: LOGIN_ERROR, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. 
                    Please check your registered mail inbox to verify your email ID`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 401 ){
                dispatch({type: LOGIN_ERROR, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. Wrong password!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 404 ){
                dispatch({type: LOGIN_ERROR, payload: err.response.data.error});
                NotificationManager.error(err.response.data.error);
                dispatch(push("/"));
            }
            else{
                // dispatch({type: LOGIN_ERROR, payload: err.response.data.errors});
                // NotificationManager.error(`Internal Server error - please try again later!`);
                dispatch(push("/"));
            }
        })
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
        dispatch(push("/"));
        NotificationManager.success('You are logged out!');
    }
}

export const verifyAuthCode = (loginEmail, verificationCode) => {
    return (dispatch) => {
        const verifyPayload = {
            emailId: loginEmail,
            verificationCode: verificationCode
        }

        // dispatch({ type: LOGIN });
        loginVerifyCode(verifyPayload).then((res) => {
            if(res && res.status === 200 && res.data){
                dispatch({ type: VERIFY_SUCCESS });
                NotificationManager.success
                ('Account verified, Please login');
    
            }
        }).catch( err => {
            if(err.response && err.response.status === 400){
                dispatch({type: VERIFY_FAIL, payload: err.response.data.errors});
                NotificationManager.error(`verification failed!`);
                dispatch(push("/"));
            }
            if(err.response && err.response.status === 422){
                dispatch({type: VERIFY_FAIL, payload: err.response.data.errors});
                NotificationManager.error(`Invalid request to login!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 403 ){
                dispatch({type: VERIFY_FAIL, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. 
                    Please check your registered mail inbox to verify your email ID`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 401 ){
                dispatch({type: VERIFY_FAIL, payload: err.response.data.error});
                NotificationManager.error(`${err.response.data.error}. Wrong password!`);
                dispatch(push("/"));
            }
            else if(err.response && err.response.status === 404 ){
                dispatch({type: VERIFY_FAIL, payload: err.response.data.error});
                NotificationManager.error(err.response.data.error);
                dispatch(push("/"));
            }
            else{
                dispatch({type: VERIFY_FAIL, payload: err.response.data.errors});
                NotificationManager.error(`Internal Server error - please try again later!`);
                dispatch(push("/"));
            }
        })
    }
}

