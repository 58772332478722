import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

//new user registration
export const registerUser = (payload) => {
    return svcRequest.post(config.register, payload, {
        timeout: 30000
      }
    );
}

export const forgotPwd = (payload) => {
  return svcRequest.post(config.forgotPwd, payload, {
    timeout: 30000      
  })
}

//test
export const ownershipType = () => {
  return svcRequest.get(config.codeValues, { params:{codeSetNumber:'3'}})
}

export const businessType = () => {
  return svcRequest.get(config.codeValues, { params:{codeSetNumber:'2'}})
}

export const sicCodes = (payload) => {
  return svcRequest.get(config.codeValues, {params:{codeSetNumber:'4', cdfMeaning: payload}})
}

export const assetTypes = () => {
  return svcRequest.get(config.codeValues, { params: { codeSetNumber: '8' } })
}
//user login verify
export const loginVerify = (payload) => {
    return svcRequest.post(config.loginVerify, payload, {
        timeout: 30000
      }
    );
}

//user login verifyCode
export const loginVerifyCode = (payload) => {
  return svcRequest.post(config.verifyCode, payload, {
      timeout: 30000
    }
  );
}