import { createSelector } from "reselect";

const inventoryPayload = (state) => state.inventoryInfo.inventoryInfo;
const isSubmitting = (state) => state.inventoryInfo.isSubmitting;
const isUpdating = (state) => state.inventoryInfo.isUpdating;
const secondFormSection = (state) => state.inventoryInfo.secondFormSection;
const thirdFormSection = (state) => state.inventoryInfo.thirdFormSection;
const invResaleFlag = (state) => state.inventoryInfo.invResaleFlag;

export const getInventoryInfo = createSelector([inventoryPayload], (data) => data);
export const getIsSubmitting = createSelector([isSubmitting], (data) => data);
export const getIsInventoryUpdating = createSelector([isUpdating], (data) => data);
export const getSecondFormSectionStatus = createSelector([secondFormSection], (data) => data);
export const getThirdFormSectionStatus = createSelector([thirdFormSection], (data) => data);
export const getInventoryResaleFlagStatus = createSelector([invResaleFlag], (data) => data);