import React from "react";
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import { Toolbar, Typography, Button} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useSelector } from 'react-redux';
import { getAppMode } from "../components/appClient/AppClientSelectors";
import { setBulkModalState } from '../components/appClient/AppClientActions';
import { useDispatch } from 'react-redux';
import { theme } from "../styles/mainPagesCSS";

const useStyles = makeStyles(() => ({
  toolbar: {
    color: 'black'
  },
  buttons: {
    color: 'black',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
    transform: 'translateY(-6px)',
    marginRight: '12px',
    "&:hover": {
      transform: 'translateY(-3px)'
    },
    "&:disabled": {
      backgroundColor: 'grey',
      cursor: 'not-allowed',
      pointerEvents: 'all !important',
      transform: 'translateY(0px)'
    }
  },
  buttonDelete: {
    color: 'white',
    backgroundColor: 'red',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
    transform: 'translateY(-6px)',
    marginRight: '12px',
    "&:hover": {
      transform: 'translateY(-3px)',
      color: 'red',
      backgroundColor: '#AA4A44'
    },
    "&:disabled": {
      backgroundColor: 'grey',
      color:'black',
      cursor: 'not-allowed',
      pointerEvents: 'all !important',
      transform: 'translateY(0px)'
    }
  }, 
  deleteButtonText: {
    color: 'white',
    fontWeight: '600',
    fontSize: '12px'
  },
  textDecor:{
    color: 'black',
    fontWeight: '600',
    fontSize: '12px'
  },
  textDecorSuper:{
    color: 'black',
    fontWeight: '600',
    fontSize: '15px'
  }
}));

const DynamicTableToolbar = (props) => {
    const { numSelected, isAssets, editBlockFlag } = props;
    const classes = useStyles();
    const appMode = useSelector(getAppMode);
   
    const dispatch = useDispatch();
   
    return (
      <Toolbar className={classes.toolbar}>
         {numSelected > 0 && (
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="h6" className={classes.textDecorSuper}>
            {numSelected} selected
          </Typography>
        )} 
          {numSelected > 0 ? (
            <>
               <ThemeProvider theme={theme}>
                    <Stack spacing={2} style={{ width:'100%' }} direction="row" justifyContent="right">
                        <Button color="secondary" variant="contained" size="medium" onClick={props.delete} startIcon={<DeleteIcon color='error'/>} disabled={appMode}>Delete Asset</Button>
                        <Button color="secondary" variant="contained" size="medium" onClick={props.edit} startIcon={<EditIcon />} disabled={editBlockFlag}>Edit Asset</Button>
                    </Stack>
                </ThemeProvider>
            </>
          ) : (
            <>
              <ThemeProvider theme={theme}>
                <Stack spacing={2} style={{ width:'100%' }} direction="row" justifyContent="right">
                    <Button color="secondary" variant="contained" size="medium" onClick={props.func} startIcon={<AddBoxIcon/>} disabled={appMode}>Add Asset</Button>
                    <Button color="secondary" variant="contained" size="medium" onClick={props.filter} startIcon={<FilterListIcon />}>Filter Assets</Button>
                    {
                      isAssets && 
                      <Button color="secondary" variant="contained" size="medium" onClick={(event)=>{dispatch(setBulkModalState(true))}} startIcon={<DriveFolderUploadIcon />}>Upload Assets</Button>
                    }
                </Stack>
              </ThemeProvider>
            </>
          )}
       
      </Toolbar>
    );
  };
  
DynamicTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};
  
export default DynamicTableToolbar;