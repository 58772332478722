import React, {useState} from "react";
import { Grid, TextField, Button, InputLabel, Select, FormControl, MenuItem, Toolbar,Stack,ThemeProvider} from '@mui/material';

import { getAssetTypes } from "../selectors/AssetsInfoSelector";
import { useSelector } from "react-redux";
import useStyles,{theme} from '../../../styles/mainPagesCSS';


const FilterForm = (props) => {
    
    const classes = useStyles();
    const ASSET_TYPES = useSelector(getAssetTypes);
    const [filterParam, setFilterParam] = useState("");
    const [assetType, setAssetType] = useState("");
    
    const handleChange = (evt, txt) => {
        switch (txt) {
            case 'description':
                setFilterParam(evt.target.value);
                break;
            case 'assetType':
                setAssetType(evt.target.value);
                break;
            default:
        }
            
    }

    return (
                <Toolbar>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                                <TextField
                                    required
                                    id="Desc"
                                    label="Description"
                                    variant="outlined"
                                    className={classes.fields}
                                    onChange={(e)=>handleChange(e,'description')}
                                    value={filterParam}
                                /> 
                            </Grid>
                            <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                          
                                <FormControl  style={{width:'20vw'}}>
                                    <InputLabel htmlFor="assetType">Asset Type</InputLabel>
                                    <Select
                                        required
                                        labelId="assetType"
                                        id="assetType"
                                        label="taxYear"
                                        onChange={(e)=>{handleChange(e,'assetType')}}
                                        value={assetType}
                                        >
                                    {ASSET_TYPES.map((assetType) => (
                                    <MenuItem  key={assetType.id}  value={assetType.id}>
                                        {assetType.display}
                                    </MenuItem>
                                     ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                            <ThemeProvider theme={theme}>
                                <Stack spacing={2} style={{ width:'100%' }} direction="row" justifyContent="right">
                                <Button 
                                  variant="contained" size="medium" 
                                    onClick={()=>{props.filterOptions(filterParam, assetType)}}    
                                >
                                    Apply Filter 
                                </Button>
                                <Button 
                                  color="secondary" variant="contained" size="medium" 
                                    onClick={() => { props.filterOptions('', ''); setFilterParam(''); setAssetType('');}}    
                                >
                                    Clear Filter 
                                </Button>
                                </Stack>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                </Toolbar>
    )
};
  
export default FilterForm;