import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const postSuppliesInfo = (payload) => {
    return svcRequest.post(config.supplies, payload, {
      timeout: 30000
    });
}

export const getSuppliesInfo = (locationId, taxFillingId, taxYear) => {
    return svcRequest.get(config.supplies, {params:{'locationInfoId': locationId, 'taxFilingPlanId': taxFillingId, 'taxYear':taxYear}}, {
        timeout: 30000
    });
}
  
export const patchSuppliesInfo = (suppliesid, payload) => {
    return svcRequest.patch(config.supplies+suppliesid, payload, {
        timeout: 30000
    });
}