import { createSelector } from "reselect";

const agentProfilePayload = (state) => state.agentProfile;
const agentProfile = (state) => state.agentProfile.agentprofile;
const isSubmitting = (state) => state.agentProfile.isSubmitting;
const isLoadingStateCity = (state) => state.agentProfile.loadingStateCity;
const loadedCity = (state) => state.agentProfile.city;
const loadedState = (state) => state.agentProfile.State; 
const isPartialAgentProfile = (state) => state.agentProfile.isPartialAgentProfile;

export const getAgentProfilePayload = createSelector([agentProfilePayload], (data) => data);
export const getAgentProfile = createSelector([agentProfile], (data) => data);
export const getIsSubmitting = createSelector([isSubmitting], (data) => data);
export const getIsLoadingStateCity = createSelector([isLoadingStateCity], (data) => data);
export const getLoadedCity = createSelector([loadedCity], (data) => data);
export const getLoadedState = createSelector([loadedState], (data) => data);
export const getIsPartialAgentProfile = createSelector([isPartialAgentProfile], (data) => data);