import { createSelector } from "reselect";

const isClientAuthenticated = (state) => state.appCtx.isClientAuthenticated;
const isAuthorized = (state) => state.appCtx.isAuthorized;
const isLoggingIn = (state) => state.appCtx.isLoggingIn;
const isVerified = (state) => state.appCtx.isVerified;

export const getIsClientAuthenticated = createSelector([isClientAuthenticated], (data) => data);
export const getIsAuthorized = createSelector([isAuthorized], (data) => data);
export const getIsLoggingIn = createSelector([isLoggingIn], (data) => data);
export const getIsVerified = createSelector([isVerified], (data) => data);