import {SET_ERROR} from '../actions/AppServiceInfoAction';

const initialState = {
    errorMessage: '',
    statusCode: 400,
    isError: false,
    isPageSubmitted: false
}

const appServiceInfoReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ERROR :
            return {...state, ...action.payload}
        default:
            return state;
    }

}



export default appServiceInfoReducer;