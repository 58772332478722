import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const postLocationInfo = (payload) => {
    return svcRequest.post(config.locationInfo, payload, {
      timeout: 30000
    });
}
  
export const getLocationInfoRelatedToBusiness = (businessId) => {
    return svcRequest.get(config.locationInfo, { params: { businessInfoId: businessId }} , {
        timeout: 30000
    });
}

export const getLocationInfoFromLocId = (locId) => {
    return svcRequest.get(config.locationInfo + '/'+ locId, {
        timeout:30000
    });
}
  
export const updateLocationInfoFromLocId = (locId, payload) => {
    return svcRequest.patch(config.locationInfo + '/' + locId, payload, {
        timeout: 30000
    });
}