import {
    SET_AGENT_PROFILE_DETAILS, SET_AGENT_PROFILE_DETAILS_SUCCESS, SET_AGENT_PROFILE_DETAILS_ERROR,
    FETCH_CITY_STATE, FETCH_CITY_STATE_FAILURE, FETCH_CITY_STATE_SUCCESS, GET_AGENT_PROFILE_DETAILS, 
    GET_AGENT_PROFILE_DETAILS_SUCCESS, GET_AGENT_PROFILE_DETAILS_ERROR, SET_PARTIAL_AGENTPROFILEINFO_SUCCESS,
    SET_AGENTPROFILE_ADDRESS_INFO, SET_AGENT_PROFILE_UPDATE_SUCCESS,SET_AGENT_ADDRESS_SELECTED_FLAG
} from "./AgentProfileActions";

const defaultState = {
    agentprofile: {
        id: 0,
        name: '',
        email: '',
        phoneNum: 0,
        addressLine1: '',
        addressLine2: '',
        zip: '',
        state: "",
        city: '',
        codes:[],
        loadingStateCity: false
    },
    isSubmitting: false,
    isPartialAgentProfile: false,
    addressSelected: false,
    modalState: false
}

const AgentProfileReducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_AGENT_PROFILE_DETAILS:
            return {...state, isSubmitting: true }
        case SET_AGENT_PROFILE_DETAILS_SUCCESS:
            return {...state, agentprofile: action.payload, isSubmitting: false }
        case SET_AGENT_PROFILE_DETAILS_ERROR: 
            return { state: defaultState }
        case FETCH_CITY_STATE: 
            return { ...state, loadingStateCity: true}
        case FETCH_CITY_STATE_SUCCESS: 
            return { ...state.agentprofile, State: action.payload.State, city: action.payload.City, loadingStateCity: false}
        case FETCH_CITY_STATE_FAILURE: 
            return { ...state.agentprofile, loadingStateCity: false }
        case GET_AGENT_PROFILE_DETAILS:
            return {...state, isSubmitting: true }
        case GET_AGENT_PROFILE_DETAILS_SUCCESS:
            return {...state, agentprofile: action.payload, isSubmitting: false }
        case GET_AGENT_PROFILE_DETAILS_ERROR: 
            return { state: defaultState }    
        case SET_PARTIAL_AGENTPROFILEINFO_SUCCESS:
            {return {...state, agentprofile: action.payload, isPartialAgentProfile: true}}
        case SET_AGENTPROFILE_ADDRESS_INFO:
            {
                return {
                    ...state, 
                    agentprofile: {
                        ...state.agentprofile,
                        address: {
                            zipcode: action.payload.zip,
                            line1: action.payload.addressLine1,
                            line2: action.payload.addressLine2,
                        }
                    },
                    isPartialAgentProfile: false, 
                    addressSelected: true

                }
            }
        case SET_AGENT_PROFILE_UPDATE_SUCCESS:
            return { ...state, agentprofile: action.payload, isPartialAgentProfile: false, addressSelected: false, isSubmitting: false }
        case SET_AGENT_ADDRESS_SELECTED_FLAG:
            return {...state, addressSelected: action.payload}
        default : 
            return state;
    }
}
export default AgentProfileReducer;