export const PRIMARY_COLOR = '#12395b';
export const SECONDARY_COLOR = 'white';

export const PRIMARY_BUTTON_COLOR = '#54732d';
export const SECONDARY_BUTTON_COLOR = 'white';

export const BOLD_TEXT_COLOR = '';
export const NON_BOLD_TEXT_COLOR = '';

export const TABLE_HEADER_BACKGROUND = '#12395b';
export const FILTER_FORM_BACKGROUND = '#D3D3D3';

export const CANCEL_COLOR = '#F5F5F5';