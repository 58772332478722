import { createSelector } from "reselect";

const assetInfoPayload = (state) => state.assets.assetInfo;
const assetTypes = (state) => state.assets.assetTypes;
const currentAsset = (state) => state.assets.currentAsset;
const bulkAssets = (state) => state.assets.bulkAssets;
const updateFlag = (state) => state.assets.isUpdating;


export const getAssetsPayload = createSelector([assetInfoPayload], (data) => data);
export const getAssetTypes = createSelector([assetTypes], (data) => data);
export const getCurrentAsset = createSelector([currentAsset], (data) => data);
export const getUpdateFlag = createSelector([updateFlag], (data) => data);
export const getBulkAssets = createSelector([bulkAssets], (data) => data);