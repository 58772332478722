

export const SET_ERROR = "SET_ERROR";

export const setAppServiceInfo = (errorCode,errorMessage, isError,isPageSubmitted) => {
    return dispatch => {
        dispatch({type:SET_ERROR,payload: {
            statusCode:errorCode,errorMessage:errorMessage,isError:isError,isPageSubmitted:isPageSubmitted}});
            // dispatch(setSelectedPlanFalse());
    }
}
