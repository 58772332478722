import {CONTACT_US_SUBMIT, CONTACT_US_SUBMIT_SUCCESS, CONTACT_US_SUBMIT_FAILURE} from './ContactUsAction';

const defaultState ={
    contactPayload: {
        firstName: '', lastName: '', businessName: '', addressLineOne: '', addressLineTwo: '', 
        emailId: '', phone: 0, zipCode: 0, county: '', city: '', state: 'Texas',  
    },
    isSending: false
}

const ContactUsReducer = (state = defaultState, action) => {
    switch(action.type){
        case CONTACT_US_SUBMIT: 
            return {...state, contactPayload: action.payload, isSending: true};
        case CONTACT_US_SUBMIT_SUCCESS:
        case CONTACT_US_SUBMIT_FAILURE:
            return {state: defaultState, isSending: false}
        default : 
            return state;
    }
}
export default ContactUsReducer;