import { createSelector } from "reselect";

const clientDetails = (state) => state.appClientCtx.clientDetails;
const isProfileComplete = (state) => state.appClientCtx.isProfileComplete;
const modalState = (state) => state.appClientCtx.modalState;
const currentPaneStep = (state) => state.appClientCtx.currentPaneStep;
const isLocationComplete = (state) => state.appClientCtx.isLocationComplete;
const isBusinessComplete = (state) => state.appClientCtx.isBusinessComplete;
const taxYr = (state) => state.appClientCtx.taxYr;
const businessesOfYr = (state) => state.appClientCtx.businesses;
const businessOfUser = (state) => state.appClientCtx.businesses;
const locsOfBusiness = (state) => state.appClientCtx.locations;
const currBusiness = (state) => state.appClientCtx.currBusiness;
const currLocation = (state) => state.appClientCtx.currLocation;
const appMode = (state) => state.appClientCtx.appMode;
const stateValues = (state) => state.appClientCtx.stateValues;
const countyValues = (state) => state.appClientCtx.countyValues;
const cityValues = (state) => state.appClientCtx.cityValues;
const bulkAssetsModalState = (state) => state.appClientCtx.bulkAssetsModalstate;
const isSuppliesCompelte = (state) => state.appClientCtx.suppliesStatus;
const isInventoryComplete = (state) => state.appClientCtx.inventoryStatus;
const isAssetsComplete = (state) => state.appClientCtx.assetStatus;
const isLeasedAssetsComplete = (state) => state.appClientCtx.leasedAssetStatus;
const bannerContent = (state) => state.appClientCtx.bannerContent;

export const getClientDetails = createSelector([clientDetails], (data) => data);
export const getIsProfileComplete = createSelector([isProfileComplete], (data) => data);
export const getModalState = createSelector([modalState], (data) => data);
export const getCurrentPaneStep = createSelector([currentPaneStep], (data) => data);
export const getIsLocationComplete = createSelector([isLocationComplete], (data) => data);
export const getIsBusinessComplete = createSelector([isBusinessComplete], (data) => data);
export const getTaxYr = createSelector([taxYr], (data) => data);
export const getBusinessesOfYr = createSelector([businessesOfYr], (data) => data);
export const getLocsOfBusiness = createSelector([locsOfBusiness], (data) => data);
export const getCurrBusiness = createSelector([currBusiness], (data) => data);
export const getCurrLocation = createSelector([currLocation], (data) => data);
export const getAppMode = createSelector([appMode], (data) => data);
export const getBusinessOfUser = createSelector([businessOfUser], (data) => data);
export const getStateValue = createSelector([stateValues], (data) => data);
export const getCountyValue = createSelector([countyValues], (data) => data);
export const getCityValue = createSelector([cityValues], (data) => data);
export const getBulkAssetsModalState = createSelector([bulkAssetsModalState], (data) => data);
export const getIsSuppliesComplete = createSelector([isSuppliesCompelte], (data) => data);
export const getIsInventoryComplete = createSelector([isInventoryComplete], (data) => data);
export const getIsAssetsComplete = createSelector([isAssetsComplete], (data) => data);
export const getIsLeasedAssetsComplete = createSelector([isLeasedAssetsComplete], (data) => data);
export const getBannerData = createSelector([bannerContent], (data) => data);