import { getBannerInfo } from "../../api/BannerApi";
import { getBusinessRelatedToUser } from "../../api/BusinessInfoApi";
import { stateValues, countyValues, cityValues } from "../../api/CodeValuesApi";
import { getLocationInfoRelatedToBusiness } from "../../api/LocationInfoApi";

export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS";
export const SET_MODAL_STATE = "SET_MODAL_STATE";
export const SET_CURRENT_PANE = "SET_CURRENT_PANE";
export const SET_BUSINESSINFO_STATUS = "SET_BUSINESSINFO_STATUS";
export const SET_LOCATIONINFO_STATUS = "SET_LOCATIONINFO_STATUS";
export const SET_SUPPLIESINFO_STATUS = "SET_SUPPLIESINFO_STATUS";
export const SET_INVENTORYINFO_STATUS = "SET_INVENTORYINFO_STATUS";
export const LOAD_BUSINESSES_OF_AN_YEAR = 'LOAD_BUSINESSES_OF_AN_YEAR';
export const LOAD_BUSINESSES_SUCCESS = 'LOAD_BUSINESSES_SUCCESS';
export const CURRENT_BUSINESS_STATE = 'CURRENT_BUSINESS_STATE';
export const LOAD_LOCATIONS_SUCCESS = 'LOAD_LOCATIONS_SUCCESS';
export const CURRENT_LOCATION_STATE = 'CURRENT_LOCATION_STATE';
export const SET_APP_MODE = 'SET_MODE_APP';
export const FETCH_STATE_VALUES = 'FETCH_STATE_VALUES';
export const FETCH_COUNTY_VALUES = 'FETCH_COUNTY_VALUES';
export const FETCH_CITY_VALUES = 'FETCH_CITY_VALUES';
export const SET_BULK_MODAL_STATE = "SET_BULK_MODAL_STATE";
export const SET_ASSETINFO_STATUS = 'SET_ASSETINFO_STATUS';
export const SET_LEASED_ASSETS_STATUS = 'SET_LEASED_ASSETS_STATUS';
export const FETCH_BANNER_INFO = 'FETCH_BANNER_INFO';
export const CURRENT_ADDITIONAL_BUSINESS_STATE = 'CURRENT_ADDITIONAL_BUSINESS_STATE';

export const  setClientDetails = (payload) => {
    return (dispatch) => {
        dispatch({ type: SET_CLIENT_DETAILS, payload });
    }
}

export const setProfileStatus = (flag) => {
 return(dispatch) => {
     dispatch({ type: SET_PROFILE_STATUS, payload: flag});
 }
}

export const setModalState = (flag) => {
    return(dispatch) => {
        dispatch({ type: SET_MODAL_STATE, payload: flag});
    }
}

export const setBulkModalState = (flag) => {
    return(dispatch) => {
        dispatch({ type: SET_BULK_MODAL_STATE, payload: flag});
    }
}

export const setCurrentPane = (val) => {
    return(dispatch) => {
        dispatch({ type: SET_CURRENT_PANE, payload: val});
    }
}

export const setBusinessInfoStatus = (payload) => {
    return (dispatch) => {
        dispatch({ type: SET_BUSINESSINFO_STATUS, payload });
    }
}

export const setLocationInfoStatus = (payload) => {
    return (dispatch) => {
        dispatch({ type: SET_LOCATIONINFO_STATUS, payload });
    }
}

export const setSuppliesInfoStatus = (payload) => {
    return (dispatch) => {
        dispatch({ type: SET_SUPPLIESINFO_STATUS, payload });
    }
}

export const setInventoryInfoStatus = (payload) => {
    return (dispatch) => {
        dispatch({ type: SET_INVENTORYINFO_STATUS, payload });
    }
}

export const setAssetInfoStatus = (payload) => {
    return (dispatch) => {
        dispatch({type: SET_ASSETINFO_STATUS, payload})
    }
}

export const setLeasedAssetStatus = (flag) => {
    return (dispatch) => {
        dispatch({type: SET_LEASED_ASSETS_STATUS, payload: flag})
    }
}
export const loadBusinesses = (isClient, id, taxYr) => {
    return (dispatch) => {
        let bizData = []
        dispatch({ type : LOAD_BUSINESSES_OF_AN_YEAR, payload: taxYr});
        getBusinessRelatedToUser(isClient, id).then((response) => {
            if(response && response.data && Object.keys(response.data).length !== 0) {
                console.log('response:', response);
                let data = response.data;
                data.forEach((element) => {
                    let eachData = {
                        'id': element.id,
                        'name': element.businessName
                    }
                    bizData.push(eachData)
                });
            }
            dispatch({type: LOAD_BUSINESSES_SUCCESS, payload: bizData })
        });        
    }
}

export const loadLocsOfBusiness = (biz) => {
    return (dispatch) => {
        let locData = []
        if(biz){
            getLocationInfoRelatedToBusiness(biz).then((response) => {
                if(response && response.data && Object.keys(response.data).length !== 0) {
                    let data = response.data;
                    data.forEach((element) => {
                        let eachLoc = {
                            'id': element.id,
                            'description': element.propertyDescription
                        }
                        locData.push(eachLoc);
                    });
                }
                dispatch({ type: LOAD_LOCATIONS_SUCCESS, payload: locData});
            })      
     }
    }
}

export const defineCurrLocation = (loc) => {
    return (dispatch) => {
        dispatch({ type: CURRENT_LOCATION_STATE, payload: loc });
        if (loc !== "") {
            dispatch({type: SET_LOCATIONINFO_STATUS, payload: true})
        } else {
            dispatch({type: SET_LOCATIONINFO_STATUS, payload: false})
        }
    }
}

export const defineCurrBusiness = (business) => {
    return (dispatch) => {
        dispatch({ type: CURRENT_BUSINESS_STATE, payload: business });
        if (business !== "") {
            dispatch({type: SET_BUSINESSINFO_STATUS, payload: true})
        } else {
            dispatch({type: SET_BUSINESSINFO_STATUS, payload: false})
        }
    }
}

export const defineCurrAdditionalBusiness = (additionalBusinessInfoId) => {
    return (dispatch) => {
        dispatch({ type : CURRENT_ADDITIONAL_BUSINESS_STATE, payload: additionalBusinessInfoId})
    }
}

export const defineModeForApp = (flag) => {
    return (dispatch) => {
        dispatch({type: SET_APP_MODE, payload:flag})
    }
}

export const fetchStateValues = () => {
    return (dispatch) => {
        stateValues().then((response) => {
            if (response.status === 200) {
                let data = response.data
                dispatch({type: FETCH_STATE_VALUES, payload: data})
            }
        })  
    }
}

export const fetchCountyValues = () => {
    return (dispatch) => {
        countyValues().then((response) => {
            if (response.status === 200) {
                let data = response.data
                dispatch({type: FETCH_COUNTY_VALUES, payload: data})
            }
        })
    }
}

export const fetchCityValues = () => {
    return (dispatch) => {
        cityValues().then((response) => {
            if(response.status === 200){
                let data = response.data
                dispatch({type: FETCH_CITY_VALUES, payload: data})
            }
        })
    }
}

export const getBannerContent = () => {
    return (dispatch) => {
        getBannerInfo().then((response) => {
            if (response.status === 200) {
                let data = response.data;
                dispatch({ type: FETCH_BANNER_INFO, payload: data });
            }
        })    
    }
}