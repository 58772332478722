import { createSelector } from "reselect";

const leasedAsestPayload = (state) => state.leasedAssets.leasedAssetsInfo;
const isSubmitting = (state) => state.leasedAssets.isSubmitting;
const isLoadingStateCity = (state) => state.leasedAssets.loadingStateCity;
const loadedCity = (state) => state.leasedAssets.leasedAssetsInfo.city;
const loadedState = (state) => state.leasedAssets.leasedAssetsInfo.state;
const leasedAssets = (state) => state.leasedAssets.leasedAssetsData;
const updateFlag = (state) => state.leasedAssets.isUpdating;
const isLeasedAssetComplete = (state) => state.leasedAssets.leasedAssetStatus;
const isPartialLeasedAsset = (state) => state.leasedAssets.isPartialLeasedAsset;
const uspsAddressFromLeasedAssets = (state) => state.leasedAssets.leasedAssetsInfo.uspsAddress;
const leasedAssetsAddressModal = (state) => state.leasedAssets.modalState;
const leasedAssetsAddressSelected = (state) => state.leasedAssets.addressSelected;

export const getLeasedAssetPayload = createSelector([leasedAsestPayload], (data) => data);
export const getIsSubmitting = createSelector([isSubmitting], (data) => data);
export const getIsLoadingStateCity = createSelector([isLoadingStateCity], (data) => data);
export const getLoadedCity = createSelector([loadedCity], (data) => data);
export const getLoadedState = createSelector([loadedState], (data) => data);
export const getLeasedAssets = createSelector([leasedAssets], (data) => data);
export const getUpdateFlag = createSelector([updateFlag], (data) => data);
export const getIsLeasedAssetsComplete = createSelector([isLeasedAssetComplete], (data) => data);
export const getUspsAddressForLeasedAssets = createSelector([uspsAddressFromLeasedAssets], (data) => data);
export const getIsPartialLeasedAsset = createSelector([isPartialLeasedAsset], (data) => data);
export const getLeasedAssetsModalState = createSelector([leasedAssetsAddressModal], (data) => data);
export const getLeasedAssetsAddressSelection = createSelector([leasedAssetsAddressSelected], (data) => data);