import React from "react";
import Faq from "react-faq-component";
import {data} from './faqConstant';
import './faqView.css';

const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#12395b",
    width:"80%",
    rowContentPaddingLeft: "20px",
   
    
    // rowContentColor: 'grey',
    // arrowColor: "red",
    // rowContentPaddingRight: '150px',
    
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const FaqApp = () => {

    return (
        <div className="main">
            <div className="header">
                <p className="titleQuestion">What is Business Personal Property (BPP)?</p>
                <p className="title">
            {`Business owners are required by State law to render tangible personal property that
              is used in a business or used to produce income. This property includes furniture and
              fixtures, equipment, machinery, computers, inventory held for sale or rental, raw
              materials, finished goods, and work in process.`}
                 </p>
            </div>
        <div className="faqContent">
        <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
       </div>
       
    );
};

export default FaqApp;