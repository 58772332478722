import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles,{ theme } from '../../styles/mainPagesCSS';
import {
    Grid, Typography, FormControlLabel, RadioGroup, Radio, FormLabel, Divider, Fade, Button
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';    
import { setModalState } from '../appClient/AppClientActions';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CloseIcon from '@mui/icons-material/Close';


import { getIsPartialClientProfile, getProfilePayload, getUspsAddressForUserProfile } from '../profile/userProfile/UserProfileSelectors';
import { setAddressForUser, setAddressPopUpModalState } from '../profile/userProfile/UserProfileActions';

import { getIsPartialAgentProfile, getAgentProfilePayload } from '../profile/agentProfile/AgentProfileSelectors';
import { setAddressForAgent } from '../profile/agentProfile/AgentProfileActions';

import { getBusinessInfo, getIsPartialBusiness, getUspsAddressForBusiness } from '../appClient/selectors/BusinessInfoSelector';
import { setAddressForBusiness } from '../../redux/actions/BusinessInfoAction';

import { getLocationInfo, getIsPartialLocation, getUspsAddressForLocation } from '../appClient/selectors/LocationInfoSelector';
import { setAddressForLocation } from '../../redux/actions/LocationInfoAction';

import { getIsPartialLeasedAsset ,getUspsAddressForLeasedAssets, getLeasedAssetPayload} from '../appClient/selectors/LeasedAssetSelectors';
import { setAddressForLeasedAssets } from '../../redux/actions/LeasedAsestAction';

const AddressSuggestionModal = () => {
    
    const classes = useStyles();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    
    const partialClientProfile = useSelector(getIsPartialClientProfile);
    const clientUserAddress = useSelector(getProfilePayload);
    const clientAddressResponse = useSelector(clientUserAddress ? getUspsAddressForUserProfile : '');
    
    const partialAgentProfile = useSelector(getIsPartialAgentProfile);
    const agentUserAddress = useSelector(getAgentProfilePayload);
    const agentUserAddressData = agentUserAddress.agentprofile;
    let agentAddressResponse = '';
    if (agentUserAddressData) {
        agentAddressResponse = agentUserAddressData.uspsAddress;
    }
    
    const partialBusiness = useSelector(getIsPartialBusiness);
    const bizAddressResponse = useSelector(getUspsAddressForBusiness);
    const bizUserAddress = useSelector(getBusinessInfo);

    const partialLocation = useSelector(getIsPartialLocation);
    const locAddressResponse = useSelector(getUspsAddressForLocation);
    const locUserAddress = useSelector(getLocationInfo);
    
    const partialLeasedAsset = useSelector(getIsPartialLeasedAsset);
    const leasedAssetsAddrResponse = useSelector(getUspsAddressForLeasedAssets);
    const leasedAssetesUserAddress = useSelector(getLeasedAssetPayload);
    
    const [userEnteredAddresss, setUserEnteredAddress] = useState([]);
    const [uspsAddressResponse, setUspsAddressResponse] = useState([]);

    const handleCancel = () => {
        dispatch(setModalState(false));
        dispatch(setAddressPopUpModalState(false));
    }

    const handleChoice = (e) => {
       
        if (partialClientProfile) {
            if (e.target.value === 'userAddress') {
                dispatch(setAddressForUser(clientUserAddress, 'userAddress'));
            } else {
                dispatch(setAddressForUser(clientAddressResponse, 'uspsAddress'));
            }
        }
        if (partialAgentProfile) {
            if (e.target.value === 'userAddress') {
                dispatch(setAddressForAgent(agentUserAddressData, 'userAddress'));
            } else {
                dispatch(setAddressForAgent(agentAddressResponse, 'uspsResponse'));
            }
        }
        if (partialBusiness) {
            if (e.target.value === 'userAddress') {
                dispatch(setAddressForBusiness(bizUserAddress, 'userAddress'));
            } else {
                dispatch(setAddressForBusiness(bizAddressResponse, 'uspsAddress'));
            }
            // dispatch(setCurrentPane(paneSteps.one));
        }
        if (partialLocation) {
            if (e.target.value === 'userAddress') {
                dispatch(setAddressForLocation(locUserAddress, 'userAddress'));
            } else {
                dispatch(setAddressForLocation(locAddressResponse, 'uspsAddress'));
            }
            // dispatch(setCurrentPane(paneSteps.one));
        }
        if (partialLeasedAsset) {
            if (e.target.value === 'userAddress') {
                dispatch(setAddressForLeasedAssets(leasedAssetesUserAddress, 'userAddress'));
            } else {
                dispatch(setAddressForLeasedAssets(leasedAssetsAddrResponse, 'uspsAddress'));
            }
        }
        
    }

    useEffect(() => {
       
        if (partialClientProfile) {
            setUserEnteredAddress([clientUserAddress.addressLine1, clientUserAddress.addressLine2, clientAddressResponse.City, clientAddressResponse.State, clientUserAddress.zip]);
            setUspsAddressResponse([clientAddressResponse.Address1, clientAddressResponse.Address2, clientAddressResponse.City, clientAddressResponse.State, clientAddressResponse.Zip5]);
        }
        if (partialAgentProfile) {
            setUserEnteredAddress([agentUserAddressData.address.line1, agentUserAddressData.address.line2, agentAddressResponse.City, agentAddressResponse.State, agentUserAddressData.address.zipcode]);
            setUspsAddressResponse([agentAddressResponse.Address1, agentAddressResponse.Address2, agentAddressResponse.City, agentAddressResponse.State, agentAddressResponse.Zip5]);
        }
        if (partialBusiness) {
            setUserEnteredAddress([bizUserAddress.mailingAddressLine1, bizUserAddress.mailingAddressLine2, bizAddressResponse.City, bizAddressResponse.State, bizUserAddress.zip]);
            setUspsAddressResponse([bizAddressResponse.Address1, bizAddressResponse.Address2, bizAddressResponse.City, bizAddressResponse.State, bizAddressResponse.Zip5]);
        }
        if (partialLocation) {
            setUserEnteredAddress([locUserAddress.line1, locUserAddress.line2, locAddressResponse.City, locAddressResponse.State, locUserAddress.zipcode]);
            setUspsAddressResponse([locAddressResponse.Address1, locAddressResponse.Address2, locAddressResponse.City, locAddressResponse.State, locAddressResponse.Zip5]);
        }
        if (partialLeasedAsset) {
            setUserEnteredAddress([leasedAssetesUserAddress.leaseCompMailAddr, leasedAssetsAddrResponse.City, leasedAssetsAddrResponse.State, leasedAssetesUserAddress.zip]);
            setUspsAddressResponse([leasedAssetsAddrResponse.Address1, leasedAssetsAddrResponse.City, leasedAssetsAddrResponse.State, leasedAssetsAddrResponse.Zip5]);
        }
    }, [
        partialClientProfile, partialAgentProfile,
        agentAddressResponse, agentUserAddressData,
        clientAddressResponse, clientUserAddress,
        partialBusiness, bizUserAddress, bizAddressResponse, 
        partialLocation, locUserAddress, locAddressResponse,
        partialLeasedAsset, leasedAssetesUserAddress, leasedAssetsAddrResponse
    ])

    return (
        <>
        <Grid container  className={classes.homeContainer}>
            <Grid item xs={12} md={10}>
                <div className={classes.noScrollPaneBox}>
                    <Fade in={true} timeout={1500}>
                        <div>
                            <Grid container justifyContent={'space-between'} >
                                <ThemeProvider theme={theme}>
                                    <Button color="secondary" variant="contained" size="medium" onClick={handleCancel} startIcon={<ArrowLeftIcon/>}>Back</Button>
                                    <Button style={{ float: 'right' }} color="error" variant="contained" size="medium" onClick={handleCancel} endIcon={<CloseIcon/>}>Close</Button>
                                </ThemeProvider>
                                <Grid item xs={12}  className={classes.formInputs}>
                                    <Typography variant="h4"  className={classes.loginTitle} > Address Suggestion </Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: '5px 0 15px 0', color:'#12395b'}}/>
                            <Grid container ref={formRef}>
                                <Grid item xs={12} className={classes.formInputs}>
                                    <FormLabel component="legend">Choose an Address to be used.</FormLabel>
                                    <RadioGroup
                                        row
                                        name="addressChoice"
                                        onChange={(e)=>{handleChoice(e)}}
                                        >
                                        <FormControlLabel value='userAddress' control={<Radio />} label={userEnteredAddresss.filter(Boolean).join(", ")} />
                                        <FormControlLabel value='uspsResponse' control={<Radio />} label={uspsAddressResponse.filter(Boolean).join(", ")} />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>
       </>
    )
}

export default AddressSuggestionModal;