import { SET_AGENT_REPRESENTATION_INFO_SUCCESS,FETCH_REPRESENTATION_CITY_STATE,FETCH_REPRESENTATION_CITY_STATE_SUCCESS,FETCH_REPRESENTATION_CITY_STATE_FAILURE } from '../actions/RepresentationAction';

const defaultState = {
    representation:{ 
        representationAs: 'OWNER',
        phoneNumber:'',
        ownerName:'',
        address: {
            zipcode: 0,
            city: '',
            state: '',
            county:50,
            line1:'',
            line2:''
        }
    }
}

const representationInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_AGENT_REPRESENTATION_INFO_SUCCESS:
            return { ...state, representation: action.payload }
            case FETCH_REPRESENTATION_CITY_STATE: 
            return { ...state, loadingStateCity: true}
        case FETCH_REPRESENTATION_CITY_STATE_SUCCESS: 
           {
            console.log(`state value is ${action.payload.State}`);
            return { ...state, representation: action.payload, loadingStateCity: false}
           }
        case FETCH_REPRESENTATION_CITY_STATE_FAILURE: 
            return { ...state, representation: { ...state.representation, state: '', city: ''}, loadingStateCity: false}
        default:
            return state;
    }
}

export default representationInfoReducer;