import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, SET_CLIENT_AUTH, VERIFY_FAIL, VERIFY_SUCCESS } from "./LoginAction";

const defaultState = {
    isAuthorized : false,
    isClientAuthenticated: false,
    isLoggingIn: false,
    isVerified: false
}

const LoginReducer = (state = defaultState, action) => {
    switch(action.type){
        case LOGIN: 
            return {...state, isLoggingIn: true };
        case LOGIN_SUCCESS: 
            return {...state, isLoggingIn: false, isAuthorized: true };
        case SET_CLIENT_AUTH:
            return {...state, isClientAuthenticated: action.payload};
        case LOGIN_ERROR:
            return {state: defaultState};
        case LOGOUT:
            return {state: defaultState};
        case VERIFY_SUCCESS:
            return {...state,isVerified: true};
        case VERIFY_FAIL:
            return {...state,isVerified: false};
        default : 
            return state;
    }
}
export default LoginReducer;