const { createLogger } = require('bunyan');
const LogDNAStream = require('logdna-bunyan');

const reqSerializer = (req) => {
  return {
    method: req.method,
    url: req.url,
    headers: req.headers,
    body: req.body,
  };
};
const logDNA = new LogDNAStream({
  user: 'saigorantla@outlook.com',
  password: 'BusinessPropertyTax',
  key: '876e071432e475412dc96d48f9860d5a',
});

const logger = createLogger({
  name: 'np_pts_app',
  env: process.env.NODE_ENV,
  serializers: {
    req: reqSerializer,
  },
  src: true,
  streams: [
    {
      stream: logDNA,
      level: 'Info',
      type: 'raw',
      reemitErrorEvents: true,
    },
  ],
});

exports.logger = logger;
