import React from "react";
import Faq from "react-faq-component";
import {data} from './deadlinesPenaltiesConstant';
import './faqView.css';

const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
   
    
    // rowContentColor: 'grey',
    // arrowColor: "red",
    // rowContentPaddingRight: '150px',
    
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const DeadlineView = () => {

    return (
        <div className="main">
        <div className="header">
            <p className="titleQuestion">Need Write up here</p>
            <p className="title">
        {``}
             </p>
        </div>
    <div className="faqContent">
    <Faq
            data={data}
            styles={styles}
            config={config}
        />
    </div>
   </div>
   
       
    );
};

export default DeadlineView;