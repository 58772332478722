import { forgotPwd } from '../../../api/AuthApi';
import NotificationManager from "react-notifications/lib/NotificationManager";

export const FORGOT_PWD_SUBMIT = "FORGOT_PWD_SUBMIT";
export const FORGOT_PWD_SUBMIT_SUCCESS = "FORGOT_PWD_SUBMIT_SUCCESS";
export const FORGOT_PWD_SUBMIT_ERROR = "FORGOT_PWD_SUBMIT_ERROR";

export const submit = (payload) => {
    const forgotPayload = {
        'emailId': payload
    }
    return (dispatch) => {
        dispatch({ type: FORGOT_PWD_SUBMIT , payload});
        forgotPwd(forgotPayload).then(response => {
            (response && response.data && response.status === 200) &&
            dispatch({type: FORGOT_PWD_SUBMIT_SUCCESS, payload});
            NotificationManager.success('Submitted successfully! Please check your email for login details.');    
        }).catch( err => {
            if (err.response && err.response.data && err.response.data.status) {
                dispatch({type: FORGOT_PWD_SUBMIT_ERROR, payload: err.response.data});
                NotificationManager.error('Email not found in our system, Please register.');    
            }
        })
        
    }
}
