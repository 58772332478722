import React from 'react';
import { useSelector } from 'react-redux';
import AgentProfileView from './agentProfile/AgentProfileView';
import { getIsClientAuthenticated } from '../home/login/LoginSelectors';
import UserProfileView from './userProfile/UserProfileView';

const ProfileSelection = () => {
    const isClient = useSelector(getIsClientAuthenticated);

    return (
        isClient ? <UserProfileView /> : <AgentProfileView />
    );
}

export default ProfileSelection;