import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const postLeasedAssets = (payload) => {
    return svcRequest.post(config.leasedAssets, payload, {
        timeout: 30000
    });
}

export const getLeasedAssetsInfoByLocation = (locationInfo,taxInfoId, offset, limit, description) => {
    return svcRequest.get(config.leasedAssets, {
        params: {
            locationInfoId: locationInfo, 
            offset: offset, 
            limit: limit, 
            description: description,
            taxFilingPlanId:taxInfoId,
        }
    }, {
        timeout: 30000
    });
}

export const deleteLeasedAssetInfoByID = (leasedAssetId) => {
    return svcRequest.delete(config.leasedAssets+leasedAssetId, {
        timeout: 30000
    });
}

export const deleteBulkLeasedAssets = (leasedAssets) => {
    return svcRequest.delete(config.leasedAssets + 'bulk/delete', { data: { payload: leasedAssets } }, { timeout: 300000 });
}

export const getLeasedAssetInfoByAssetId = (leasedAssetId) => {
    return svcRequest.get(config.leasedAssets+leasedAssetId, {timeout: 30000});
}

export const updateLeasedAssetInfoByAssetId = (leasedAssetId, payload) => {
    return svcRequest.patch(config.leasedAssets+leasedAssetId, payload, {timeout: 30000}); 
}
