import { createSelector } from "reselect";

const locationInfo = (state) => state.locationInfo.locationInfo;
const locationCityValue = (state) => state.locationInfo.locationInfo.city;
const locationStateValue = (state) => state.locationInfo.locationInfo.state;
const locationCountyValue = (state) => state.locationInfo.locationInfo.county;
const loadingStateCity = (state) => state.locationInfo.loadingStateCity;
const locationAddressModalState = (state) => state.locationInfo.modalState;
const isPartialLocation = (state) => state.locationInfo.isPartialLocation;
const uspsAddressFromLocation = (state) => state.locationInfo.locationInfo.uspsAddress;

export const getLocationInfo = createSelector([locationInfo], (data) => data);
export const getLocationCityValue = createSelector([locationCityValue], (data) => data); 
export const getLocationStateValue = createSelector([locationStateValue], (data) => data);
export const getLocationCountyValue = createSelector([locationCountyValue], (data) => data);
export const getIsLoadingLocationStateCityValues = createSelector([loadingStateCity], (data) => data);
export const getLocationAddressModalState = createSelector([locationAddressModalState], (data) => data);
export const getIsPartialLocation = createSelector([isPartialLocation], (data) => data);
export const getUspsAddressForLocation = createSelector([uspsAddressFromLocation], (data) => data);