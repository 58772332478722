import { makeStyles } from "@mui/styles";
import { PRIMARY_BUTTON_COLOR, PRIMARY_COLOR, SECONDARY_BUTTON_COLOR, SECONDARY_COLOR, CANCEL_COLOR } from "../constants/colorScheme";
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: PRIMARY_BUTTON_COLOR,
      darker: '#053e85',
    },
    secondary: {
        main: CANCEL_COLOR,
      }
  },
});

export const standardButtons = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
        darker: '#053e85',
      },
    },
  });
const useStyles = makeStyles(() => ({
    paneBox: {
        color: '#1A355D',
        border: '1px solid PRIMARY_COLOR',
        backgroundColor: 'white',
        boxShadow: '0px 0px 9px 4px rgb(183 163 167 / 55%)',
        padding: '20px',
        maxHeight: '550px',
        overflowY: 'scroll',
    },
    noScrollPaneBox: {
        color: '#1A355D',
        border: '1px solid PRIMARY_COLOR',
        backgroundColor: 'white',
        boxShadow: '0px 0px 9px 4px rgb(183 163 167 / 55%)',
        padding: '20px',
        maxHeight: '550px',
    },
    renditionPaneBox: {
        color: '#1A355D',
        border: '1px solid PRIMARY_COLOR',
        backgroundColor: 'white',
        boxShadow: '0px 0px 9px 4px rgb(183 163 167 / 55%)',
        padding: '20px',
        maxHeight: '550px',
        overflowY: 'scroll',
        maxWidth: '400px', 
    },
    renditionContainer: {
        color: '#2e415c',
        paddingLeft: '30%',
        justifyContent: 'center'
    },
    scrollableContent: {
        minHeight: '300px',
        maxHeight: '500px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '0 15px'
    },
    homeContainer: {
        color: '#2e415c',
        justifyContent: 'center',
    },
    loginTitle: {
        fontWeight: 'bold',
        textAlign: 'center'
      },
    formInputs:{
        padding: '10px 0',
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": { 
            color: 'red',
          }
    },
    fields: {
        width: '100%',
        "& .MuiFormLabel-root": {
            color: PRIMARY_COLOR,
            fontWeight: 'bold'
        }
    },
    disabledFields: {
        width: '100%',
        '& .MuiInputBase-root': {
            fontWeight: 'bold'
        },
        "& .MuiFormLabel-root": {
            color: 'grey',
            fontWeight: 'bold'
        }
    },
    otherFieldLabel: {
        textAlign:'left', 
        fontWeight: '600', 
        color: PRIMARY_COLOR
    },
    saveBtn: {
        color: 'white',
        width: '100%',
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundColor: PRIMARY_BUTTON_COLOR,
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
        transform: 'translateY(-6px)',
        '&:hover': {
            backgroundColor: '#768f56',
            color: 'white',
            transition: 'all 0.3s ease 0s',
            transform: 'translateY(-3px)',
            boxShadow: '0 0.5em 0.5em -0.4em grey'
        },
        "&:disabled": {
            background: "#c0c0c0",
            color: "grey",
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            transform: 'translateY(0px)',
            boxShadow: '0 0.5em 0.5em -0.4em black'
        }
    },
    cancelBtn: {
        color: 'black',
        width: '100%',
        fontWeight: 'bold',
        cursor: 'pointer',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
        transform: 'translateY(-6px)',
        backgroundColor: SECONDARY_BUTTON_COLOR,
        outline: '1px solid grey',
        '&:hover': {
            backgroundColor: '#FFFFF0',
            color: 'black',
            transition: 'all 0.3s ease 0s',
            transform: 'translateY(-3px)',
            boxShadow: '0 0.5em 0.5em -0.4em black',
        },
        "&:disabled": {
            cursor: 'not-allowed',
            color: 'black',
            pointerEvents: 'all !important',
            transform: 'translateY(0px)',
            boxShadow: '0.9em 0.9em 0.9em -0.9em black' 
        }
    },
    backBtn: {
        float: 'left',
        fontWeight: 'bold',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: SECONDARY_COLOR,
          animation: 0
      }
    },
    closeBtn: {
        fontWeight: 'bold',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: PRIMARY_COLOR,
          color: 'red',
          animation: 0
      }
    },
    ProceedBtn: {
        color: SECONDARY_COLOR,
        width: 'calc(100% - 60px)',
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundColor: PRIMARY_COLOR,
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
        // transform: 'translateY(-6px)',
        '&:hover': {
            backgroundColor: PRIMARY_COLOR,
            color: 'white',
            transition: 'all 0.3s ease 0s',
            // transform: 'translateY(-3px)',
            boxShadow: '0 0.5em 0.5em -0.4em grey'
        },
        "&:active": {
            // transform: 'translateY(-2px)'
        }, 
        "&:disabled": {
            background: "#c0c0c0",
            color: "grey",
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            // transform: 'translateY(0px)',
            boxShadow: '0 0.5em 0.5em -0.4em black'
        }
    },
    container: {
        color: '#2e415c',
        backgroundColor: 'white',
        justifyContent: 'center',
        padding: '30px',
    },
    buttonContainer: {
        backgroundColor: 'white',
        justifyContent: 'right',
        paddingTop: "4%"
    },
    title: {
        fontWeight: 'bold', 
        marginBottom: '5%'
    },
    inventoryStepperIconActive: {
        color: '#12395b !important',
    },
    bulkContainer: {
        top: '100px',
    },
    newAssetsFormContainer: {
        minHeight: '300px',
        maxHeight: '500px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '0 15px',
        color: '#2e415c',
        justifyContent: 'center'
    },
    leasedAssetsContainer: {
        color: '#2e415c',
        justifyContent: 'center'
    },
    paneModalContainer: {
        minHeight: '400px',
        maxHeight: '550px',
        overflowY: 'scroll',
        color: '#2e415c',
        justifyContent: 'center',
        padding: '0 15px',
    },
    modalRoot: {
        paddingTop: '150px',
        overflowY: 'hidden'
    },
    paneRoot: {
        color: '#2e415c',
        backgroundColor: 'transparent',
    },
    paneRootBlur: {
        color: '#2e415c',
        backgroundColor: 'transparent',
        filter: 'blur(12px)'
    },
    progress: {
        padding: '5%', 
        paddingLeft: '10%'
    }, 
    buttons: {
        paddingTop: '30px',
        paddingLeft: '50px'
    },
    renditionText: {
        paddingLeft: '10%',
        fontSize: '16px',
        fontWeight: '600'
    },
    filterButton: {
        color: 'black',
        width: '100%',
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundColor: 'white',
        marginBottom: '5px',
        marginTop: '5px',        
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 1)',
        '&:hover': {
            backgroundColor: PRIMARY_COLOR+'9f',
            color: 'black',
            transition: 'all 0.3s ease 0s',
        }
    },
    banner: {
        padding: '15px',
        backgroundColor: '#fae8af',
        color: '#3e4042',
        marginTop: '1%',
        fontSize: 'medium'
    },
    uploadPaneBox: {
        color: '#1A355D',
        border: '1px solid PRIMARY_COLOR',
        backgroundColor: 'white',
        boxShadow: '0px 0px 9px 4px rgb(183 163 167 / 55%)',
        padding: '20px',
        maxHeight: '550px',
    },
    greenMessage: {
        color: 'green',
        backgroundColor: 'white',
        border: '1px solid PRIMARY_COLOR',

    },
    customErrorMessage: {
        fontSize:'20px',
    },
    cancelButton: {
        backgroundColor: CANCEL_COLOR,
    },
    saveButton: {
        backgroundColor: PRIMARY_BUTTON_COLOR,
    },
    messageBanner: {
        padding: '10px 0',
        backgroundColor: '#ed6c02',
        color: 'white', 
        borderRadius: '4px'
    },
    pageContainer: {
            position:'relative',
            left:'2%',
            bottom:'15%',
            textAlign:'justify',
            minHeight:'360px'
    }

    
    
  }));
export default useStyles;