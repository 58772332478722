import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAuthCode } from './login/LoginAction';
import { getIsVerified } from './login/LoginSelectors';
import HomeView from './HomeView';

const Verify = () => {
    const dispatch = useDispatch();
    const isVerified = useSelector(getIsVerified);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const emailId = queryParams.get("emailId");
        const verificationCode = queryParams.get("verificationCode");
        dispatch(verifyAuthCode(emailId,verificationCode));
    },[dispatch]);
    return (
        <div>
            {isVerified && <HomeView> Account verified, Please login</HomeView> }
            {!isVerified && <div> Account verification failed</div>}
        </div>
    );
}
export default Verify;