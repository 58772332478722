import React from 'react';
import { makeStyles } from '@mui/styles';
import {CircularProgress} from '@mui/material';

const useStyles = makeStyles(() => ({
    loader: {
        position: 'fixed',
        color: 'white',
        top:'50%',
        left:'50%',
        backgroundColor: '#22477E',
        opacity: '0.7',
        borderRadius: '15px',
        padding: '10px',
        zIndex: 100
    },
}));

const Spinner = () => {
    const classes = useStyles();
    return (
        <CircularProgress className={classes.loader} />
    )
}

export default Spinner;