export const colHeaders = [
    {
      id: 'assetDescription',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'leaseNumber',
      numeric: true,
      disablePadding: false,
      label: 'Lease Number',
    },
    {
      id: 'leaseCompany',
      numeric: false,
      disablePadding: false,
      label: 'Lease Company',
    },
    {
      id: 'leaseStartDate',
      numeric: false,
      disablePadding: false,
      label: 'Lease Start Date (MM/DD/YYYY)',
    },
    {
        id: 'leaseEndDate',
        numeric: false,
        disablePadding: false,
        label: 'Lease End Date (MM/DD/YYYY)',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
    },
    {
      id: 'zip',
      numeric: true,
      disablePadding: false,
      label: 'Zip Code',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
  ];

  //TO DO- fetch data
  export const leasedAssetRowData = [
    {leasedAssetDescription : 'Test Formatter 1', leasedNumber: 2345, 
    leasingCompanyName: 'TCS', leaseStartDate: '11/16/2020',leaseEndDate: '11/16/2020', address: {line1:'4900 USAA BLVD',line2:'123', 
    zipcode: 78240, city: 'San Antonio',state:'TX'}},
    {leasedAssetDescription : 'Test Formatter 2', leasedNumber: 2346, 
    leasingCompanyName: 'Furniture', leaseStartDate: '12/16/2020', leaseEndDate: '11/16/2020', address: {line1:'4900 USAA BLVD',line2:'123', 
    zipcode: 78240, city: 'San Antonio',state:'TX'}},
    {leasedAssetDescription : 'Test Formatter 3', leasedNumber: 2347, 
    leasingCompanyName: 'Furniture', leaseStartDate: '11/20/2020',leaseEndDate: '11/16/2020',  address: {line1:'4900 USAA BLVD',line2:'123', 
    zipcode: 78240, city: 'San Antonio',state:'TX'}},
    {leasedAssetDescription : 'Test Formatter 4', leasedNumber: 2348, 
    leasingCompanyName: 'Furniture', leaseStartDate: '01/25/2021',leaseEndDate: '11/16/2020',  address: {line1:'4900 USAA BLVD',line2:'123', 
    zipcode: 78240, city: 'San Antonio',state:'TX'}},
    {leasedAssetDescription : 'Test Formatter 5', leasedNumber: 2349, 
    leasingCompanyName: 'Furniture', leaseStartDate: '01/30/2021',leaseEndDate: '11/16/2020',  address: {line1:'4900 USAA BLVD',line2:'123', 
    zipcode: 78240, city: 'San Antonio',state:'TX'}}
]