import React, { useState } from "react";
import { Grid, TextField, Button, Toolbar} from '@mui/material';
import useStyles, { theme } from '../../../styles/mainPagesCSS';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';

const LeaseAssetFilterForm = (props) => {
    
    const classes = useStyles();
    const [filterParam, setFilterParam] = useState('');
    
    const handleChange = (evt) => {
        setFilterParam(evt.target.value);
    }
    return (
        <Toolbar >
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                    <TextField
                        required
                        id="Desc"
                        label="Description"
                        variant="outlined"
                        className={classes.fields}
                        onChange={(e) => handleChange(e)}
                        value={filterParam} /> 
                </Grid>
                <Grid item xs={12} md={8} lg={4} className={classes.formInputs} style={{left:'30%',position:'relative'}}>
                <ThemeProvider theme={theme}>
                    <Stack spacing={2} style={{ width:'100%'}} direction="row" justifyContent="right">
                        <Button  variant="contained" size="medium" onClick={()=>{props.filterOptions(filterParam)}}>
                            Apply Filter
                        </Button>
                        <Button color="secondary" variant="contained" size="medium" onClick={() => { props.filterOptions(''); setFilterParam(''); }} > 
                            Clear filter 
                        </Button>
                    </Stack>
                </ThemeProvider>
                </Grid>
                {/* <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                    <Button size="medium" color="inherit" className={classes.filterButton}
                        onClick={() => {props.filterOptions(filterParam)}} >
                        Apply Filter 
                    </Button>
                </Grid>
                <Grid item xs={12} md={8} lg={4} className={classes.formInputs}>
                    <Button  size="medium" color="inherit" className={classes.filterButton}
                        onClick={() => { props.filterOptions(''); setFilterParam('')}} >
                        Clear Filter 
                    </Button>
                </Grid>   */}
            </Grid>
        </Toolbar>
    )
};
  
export default LeaseAssetFilterForm;