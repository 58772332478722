import { createSelector } from "reselect";

const businessInfo = (state) => state.businessInfo.businessInfo;
const ownershipTypes = (state) => state.businessInfo.ownershiptypes;
const businessTypes = (state) => state.businessInfo.businesstypes;
const sicCodes = (state) => state.businessInfo.siccodes;
const businessStateValue = (state) => state.businessInfo.businessInfo.state;
const businessCityValue = (state) => state.businessInfo.businessInfo.city;
const loadingStateCity = (state) => state.businessInfo.loadingStateCity;
const businessAddressModalState = (state) => state.businessInfo.modalState;
const isPartialBusiness = (state) => state.businessInfo.isPartialBusiness;
const uspsAddressFromBusiness = (state) => state.businessInfo.businessInfo.uspsAddress;

export const getBusinessInfo = createSelector([businessInfo], (data) => data);
export const getownershipTypes = createSelector([ownershipTypes], (data) => data);
export const getBusinessTypes = createSelector([businessTypes], (data) => data);
export const getSicCodes = createSelector([sicCodes], (data) => data);
export const getBusinessStateValue = createSelector([businessStateValue], (data) => data);
export const getBusinessCityValue = createSelector([businessCityValue], (data) => data);
export const getIsLoadingBusinessStateCityValues = createSelector([loadingStateCity], (data) => data);
export const getBusinessAddressModalState = createSelector([businessAddressModalState], (data) => data);
export const getIsPartialBusiness = createSelector([isPartialBusiness], (data) => data);
export const getUspsAddressForBusiness = createSelector([uspsAddressFromBusiness], (data) => data);