import axios from "axios";


let svcRequest = axios.create();
svcRequest.interceptors.request.use((req) => {
    req.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    }

    return req;
}, (error) => {
    return Promise.reject(error);
});

svcRequest.interceptors.response.use((res) => {
    return res;
}, (error) => {
    if(error.message === "Network Error"){
    }

    return Promise.reject(error);
});

export { svcRequest }