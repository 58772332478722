import { createSelector } from "reselect";

const profilePayload = (state) => state.userProfile.profile;
const directClientInfoId = (state) => {
    let id = '';
    if( state.userProfile &&  state.userProfile.profile &&  state.userProfile.profile.id) {
      id = state.userProfile.profile.id;
    }
    return id;
}
const isSubmitting = (state) => state.userProfile.isSubmitting;
const isLoadingStateCity = (state) => state.userProfile.loadingStateCity;
const loadedCity = (state) => state.userProfile.profile.city;
const loadedState = (state) => state.userProfile.profile.state;
const isClient = (state) => state.appCtx.isClientAuthenticated;
const uspsAddressFromClientProfile = (state) => state.userProfile.profile.uspsAddress;
const isPartialClientProfile = (state) => state.userProfile.isPartialClientProfile;
const addressModalState = (state) => state.userProfile.modalState;

export const getProfilePayload = createSelector([profilePayload], (data) => data);
export const getIsSubmitting = createSelector([isSubmitting], (data) => data);
export const getIsLoadingStateCity = createSelector([isLoadingStateCity], (data) => data);
export const getLoadedCity = createSelector([loadedCity], (data) => data);
export const getLoadedState = createSelector([loadedState], (data) => data);
export const getClientCheckDetails = createSelector([isClient], (data) => data);
export const getUspsAddressForUserProfile = createSelector([uspsAddressFromClientProfile], (data) => data);
export const getIsPartialClientProfile = createSelector([isPartialClientProfile], (data) => data);
export const getAddressModalState = createSelector([addressModalState], (data) => data);
export const getDirectClientInfoId = createSelector([directClientInfoId], (data) => data);