export const data = {
    // title: "FAQ",
    rows: [
        {
            title: "BPP Rendition",
            content: "The last day to file your rendition is April 15 annually. If you mail your rendition, it "+
            "must be postmarked by the U.S. Postal Service on or before April 15. If you hand "+
            "deliver it or use a private delivery service such as Federal Express, it must be "+
            "physically received in our office before close of business on April 15. If you fail to "+
            "file your rendition before the deadline or you do not file it at all, a penalty "+
            "equal to 10% of the amount of taxes ultimately imposed on the property "+
            "will be levied against you."
        },
        {
            title: "BPP Rendition Extension",
            content: "The law provides for an extension of time to file a rendition. In order to receive the "+
            "extension, you must submit the request to the Dallas Central Appraisal District in "+
            "writing by the April 15 rendition filing deadline. With the receipt of a timely "+
            "extension request, the rendition filing deadline will be extended to May 15. If you "+
            "fail to file your rendition before the extended deadline or you do not file it "+
            "at all, a penalty equal to 10% of the amount of taxes ultimately imposed on "+
            "the property will be levied against you."
        },
        {
            title: "Utility Rendition",
            content: "Rendition statements and property reports required to be filed by a property owner "+
            "regulated by the Public Utility Commission of Texas, the Railroad Commission of "+
            "Texas, the Federal Surface Transportation Board, or the Federal Energy Regulatory "+
            "Commission must be delivered to the chief appraiser not later than April 30. On "+
            "written request by the property owner on or before April 30, the chief appraiser "+
            "shall extend the filing deadline to May 15. If you fail to file your rendition "+
            "before the deadline or you do not file it at all, a penalty equal to 10% of the "+
            "amount of taxes ultimately imposed on the property will be levied against you."
        },
        {
            title: "Interstate Allocation Application",
            content: "The Application for Interstate Allocation of Value must be filed no later than April 30 "+
            "annually. A new account’s allocation application deadline is extended to 30 days after "+
            "the receipt of a notice of appraised value. Late applications can be accepted up "+
            "until the day before the appraisal review board approves the appraisal "+
            "records, but there is a 10% penalty against the portion of the allocated "+
            "property that is outside of Texas."
        },
        {
            title: "Freeport Exemption Application",
            content: "The property owner or agent must file the application no later than April 30 annually. "+
            "Late applications can be accepted up until June 15, but there is a 10% "+
            "penalty against the exempt portion of the inventory."
        },
        {
            title: "Goods-In-Transit Exemption Application",
            content: "The property owner or agent must file the application no later than April 30 annually. "+
            "Late applications will not be accepted and the exemption will be forfeited for the "+
            "current tax year."
        },
        {
            title: "Motor Vehicle Used for Production of Income and Personal Use Exemption Application",
            content: "This exemption application must be filed by April 30, but does not have to be "+
            "filed annually. Late applications will not be accepted and the exemption will be "+
            "forfeited for the current tax year."
        },
        {
            title: "Pollution Control Exemption Application",
            content: "This exemption application must be filed by April 30, but does not have to be "+
            "filed annually. Late applications will not be accepted and the exemption will be "+
            "forfeited for the current tax year."
        },
        {
            title: "September 1 Inventory Appraisal Application",
            content: "The last day for filing this form is July 31 and does not have to be filed annually. The "+
            "application applies to the appraisal of the inventory in each tax year that begins after "+
            "the next August 1 following the date the application is filed. If you file it after July "+
            "31, September 1 appraisal will be delayed a year."
        },
        {
            title: "September 1 Inventory Appraisal Revocation",
            content: "The last day to revoke September 1 appraisal is August 31. A notice revoking the "+
            "application is effective for each tax year that begins after the next September "+
            "-following the date the notice of revocation is filed. If you file it after August 31, "+
            "the revocation of September 1 appraisal will be delayed a year."
        },
        {
            title: `Dealer's Motor Vehicle / Heavy Equipment / Vessel, Trailer and Outboard Motor / Retail
            Manufactured Housing Inventory Declaration `,
            content: "You must file the dealer's inventory declaration with the Chief Appraiser and a copy with "+
            "the county tax assessor-collector not later than February 1 of each year. If you were "+
            "not business on January 1, you must file the declaration not later than 30 days after "+
            "starting business. Failure to file this form is a misdemeanor offense punishable "+
            "by a fine not to exceed $500. Each day during which you fail to comply is a "+
            "separate offense. In addition, a tax lien attaches to your business personal "+
            "property to secure the penalty's payment. You also will forfeit a penalty of "+
            "$1,000 for each month or part of a month in which the declaration in not filed."
        },
        {
            title: `Dealer's Motor Vehicle / Vessel, Trailer and Outboard Motor / Retail Manufactured
            Housing Inventory Tax Statement`,
            content: "You must file the dealer’s inventory tax statement with the Chief Appraiser and a copy "+
            "of the original statement with your monthly tax payment with the county tax assessorcollector on or before the 10th day of each month. If you were not in business for the "+
            "entire year, you must file this statement each month after your business opens, but do "+
            "not include any tax payment until the beginning of the next calendar year. Late filing "+
            "incurs a penalty of five percent of the amount due. If the amount due is not "+
            "paid within 10 days after the due date, the penalty increases an additional "+
            "five percent of the amount due. Failure to file this form is a misdemeanor "+
            "offense punishable by a fine not to exceed $100. Each day that you fail to "+
            "comply is a separate offense. In addition, a tax lien attaches to your business "+
            "personal property to secure the penalty’s payment. You also will forfeit a "+
            "penalty of $500 for each month or part of a month in which this statement is "+
            "not filed after it is due."
        },
        {
            title: "Dealer's Heavy Equipment Inventory Tax Statement",
            content: "You must file the dealer's inventory tax statement with the Chief Appraiser and a copy "+
            "of the original statement with your monthly tax payment with the county tax assessorcollector on or before the 20th day of each month. If you were not in business for the "+
            "entire year, you must file this statement each month after your business opens, but do "+
            "not include any tax payment until the beginning of the next calendar year. Late filing "+
            "incurs a penalty of five percent of the amount due. If the amount due is not "+
            "paid within 10 days after the due date, the penalty increases an additional "+
            "five percent of the amount due. Failure to file this form is a misdemeanor "+
            "offense punishable by a fine not to exceed $100. Each day that you fail to "+
            "comply is a separate offense. In addition, a tax lien attaches to your business "+
            "personal property to secure the penalty's payment. You also will forfeit a "+
            "penalty of $500 for each month or part of a month in which this statement is "+
            "not filed after it is due."
        },
        {
            title: "Motor Vehicles Leased for Use Other Than Production of Income Exemption",
            content: "Lessor must file for the exemption by April 30. The deadline is not extended by an "+
            "approved rendition extension. By indicating whether or not the vehicle is held for the "+
            "production of income on a timely rendition, the lessor is applying for the exemption. "+
            "Late filings will not be accepted and the exemption will be forfeited for the "+
            "current tax year."
        },
    ]
}