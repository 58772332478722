import axios from "axios";
import { NotificationManager } from 'react-notifications';
const { logger } = require('../../src/logger');


let svcRequest = axios.create();
svcRequest.interceptors.request.use((req) => {
    req.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    }

    return req;
}, (error) => {
    logger.error(`Failed to intercept request -> ${error}`);
    return Promise.reject(error);
});

svcRequest.interceptors.response.use((res) => {
    return res;
}, (error) => {
    if(error.message === "Network Error"){
        logger.error(`Failed service call with network error -> ${error}`);
        NotificationManager.error(error.message);
    }

    return Promise.reject(error);
});

export { svcRequest }