import {
    SET_LOCATION_INFO, SET_LOCATION_INFO_SUCCESS, SET_LOCATION_INFO_ERROR,
    FETCH_LOCATION_CITY_STATE, FETCH_LOCATION_CITY_STATE_SUCCESS, FETCH_LOCATION_CITY_STATE_FAILURE,
    FETCH_COUNTY_INFO, FETCH_COUNTY_INFO_SUCCESS, FETCH_COUNTY_INFO_FAILURE, LOCATION_RESET, SET_UPDATE_LOCATION_INFO,
    SET_UPDATE_LOCATION_INFO_SUCCESS, SET_UPDATE_LOCATION_INFO_FAILURE, SET_PARTIAL_LOCATION_INFO_SUCCESS, 
    SET_LOCATION_ADDRESS_INFO, GET_LOCATION_INFO, GET_LOCATION_INFO_SUCCESS, GET_LOCATION_INFO_FAILURE, 
    SET_LOCATION_ADDRESS_MODAL_STATE
} from "../actions/LocationInfoAction";

const defaultState = {
    locationInfo: {
        propertyDescription: '',
        totalSqft: 0,
        countyPropertyId: '',
        line1: '',
        line2: '',
        zipcode: '',
        state: "",
        city: '',
        county: '',
        loadingStateCity: false
    },
    isLocationSubmitting: false,
    isPartialLocation: false,
    addressSelected: false,
    modalState: false
}

const locationInfoReducer = (state = defaultState, action) => {
    switch(action.type){
        case GET_LOCATION_INFO:
            return { ...state, locationInfo: {...action.payload,isSubmitting:'no' } }
        case GET_LOCATION_INFO_SUCCESS:
            return { ...state, locationInfo: {...action.payload,isSubmitting:'no' } }
        case GET_LOCATION_INFO_FAILURE:
            return { ...state, locationInfo: {...action.payload,isSubmitting:'no' } }
        case SET_LOCATION_INFO:
            return {...state, isSubmitting: true }
        case SET_LOCATION_INFO_SUCCESS:
            return {...state, locationInfo: {...action.payload, isSubmitting: 'yes', error: false} }
        case SET_LOCATION_INFO_ERROR: 
            return {...state, locationInfo: {...action.payload, isSubmitting: 'yes', error: true,errorResponse: action.payload.error } }
        case FETCH_LOCATION_CITY_STATE: 
            return { ...state, loadingStateCity: true}
        case FETCH_LOCATION_CITY_STATE_SUCCESS: 
            return { ...state, locationInfo: action.payload, loadingStateCity: false }
        case FETCH_LOCATION_CITY_STATE_FAILURE: 
            return { ...state, locationInfo: {...action.payload,state: '', city: ''},loadingStateCity: false }
        case FETCH_COUNTY_INFO:
        case FETCH_COUNTY_INFO_FAILURE:
            return { ...state }
        case FETCH_COUNTY_INFO_SUCCESS:
           { return {...state, locationInfo:{...state.locationInfo, county: action.payload}}}
        case SET_UPDATE_LOCATION_INFO_SUCCESS:
            { return { ...state, locationInfo: action.payload, isSubmitting: false}}
        case SET_UPDATE_LOCATION_INFO_FAILURE:
        case SET_UPDATE_LOCATION_INFO:
        case LOCATION_RESET: 
            return {...state, locationInfo: defaultState.locationInfo, isPartialLocation: false, addressSelected: false}
        case SET_PARTIAL_LOCATION_INFO_SUCCESS:
            {return {...state, locationInfo: action.payload, isPartialLocation: true }}
        case SET_LOCATION_ADDRESS_INFO:
           { return {
                ...state,
                locationInfo: {
                    ...state.locationInfo,
                    line1: action.payload.mailingAddressLine1,
                    line2: action.payload.mailingAddressLine2
                },
                isPartialLocation: false,
                addressSelected: true
            }}
        case SET_LOCATION_ADDRESS_MODAL_STATE:
            return {...state, modalState: action.payload}
        default: 
            return state;
    }
}
export default locationInfoReducer;