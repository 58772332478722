import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { getBusinessInfo } from '../selectors/BusinessInfoSelector';
import { getLocationInfo } from '../selectors/LocationInfoSelector';
import { getTaxInfo } from "../selectors/TaxInfoSelector";
import { useHistory } from 'react-router';
import { makeStyles } from '@mui/styles';
import {deleteTaxInfo} from '../../../redux/actions/taxInfoAction';
import { getDirectClientInfoId } from '../../profile/userProfile/UserProfileSelectors';
import { getAgentProfile } from '../../profile/agentProfile/AgentProfileSelectors';

const useStyles = makeStyles((theme) => ({
    
  paneContainer: {
    [theme.breakpoints.down("md")]: {
      left: '30%',
      position: 'relative',
      bottom:'10%',
      width:'700px',
      height:'300px'
    },
  },
  buttonStyle: {
    [theme.breakpoints.down("md")]: {
      position:'relative',
      left: '50%',
    }
  },
  boxStyle : {
    width: '400px',
    height:'250px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  },
  buttonCustomStyle : {
    width: '200px',
    height: '40px',
    position: 'relative',
    left: '25%',
    top: '20%'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
 
  

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DuplicateTaxModal() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const businessInfoData = useSelector(getBusinessInfo);
  const taxInfoData = useSelector(getTaxInfo);
  const locationInfoData = useSelector(getLocationInfo);
  const dispatch = useDispatch();
  const directClientInfoId = useSelector(getDirectClientInfoId);
  const agentProfile = useSelector(getAgentProfile);
//   const [businessInfo, setBusinessInfo] = useState({ ...businessInfoData });

const history = useHistory();

  useEffect(() => {
      if((businessInfoData.error || taxInfoData.error) && businessInfoData.isSubmitting === 'yes') {
        let error = businessInfoData.errorResponse;
        if(error === null || error === undefined) {
          error = taxInfoData.errorResponse;
        }
        if(error && error.response && error.response.status === 402){
           setOpen(true);
        }
       
      }
},[businessInfoData])

useEffect(() => {
  if((locationInfoData.error || taxInfoData.error) && locationInfoData.isSubmitting === 'yes') {
    let error = locationInfoData.errorResponse;
    if(error === null || error === undefined) {
      error = taxInfoData.errorResponse
    }
    if(error && error.response && error.response.status === 402){
     setOpen(true);
    }
  }
},[locationInfoData,taxInfoData])

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = () => {
    history.push('/historyScreen');
  };

  const displayMessage = () => {

    return taxInfoData.error ? `It seems like you already a plan in progress with TaxFiling Year:${taxInfoData.taxYear} , 
          business: ${businessInfoData.businessName} ,location: ${locationInfoData.propertyDescription} ` : businessInfoData.error ? "It seems like you are trying to save Business details which you have already saved."+ 
           "Please select Business from dropdown." : "It seems like you are trying to save Location details which you have already saved."+ 
           "Please select Location from dropdown.";
  }

  const displayTitle = () => {
    return taxInfoData.error? "Duplicate Tax Filing Plan" : businessInfoData.error ? "Duplicate Business Information" : "Duplicate Location Information"
  }

  return (
    <div >
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
       {/* <Box className={classes.boxStyle}> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        
      >
        <Box className={classes.boxStyle} >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
         {displayTitle()} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
           
          </Typography>
          
           { 
           displayMessage()
           }
        </DialogContent>
      
       
          <Button type="button" className={classes.buttonCustomStyle} onClick={ e => {
                 navigate();
                 dispatch(deleteTaxInfo(taxInfoData.id,directClientInfoId,agentProfile));
              }} variant="contained" size="medium" sx={{ mr: 1 , width: '50px',height:'20px' }}>
                Delete Plan
              </Button>
              
              </Box>
      </BootstrapDialog>
      {/* </Box> */}
    </div>
  );
}
