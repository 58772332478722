import { 
    GET_TAX_FILING_PLANS_INFO,GET_TAX_FILING_PLAN_INFO, SET_SELECTED_PLAN_FALSE, SET_NEW_PLAN_TRUE,SET_NEW_PLAN_FALSE, RESET_TAX_INFO
} from '../actions/taxFilingPlanAction';
import { PUT_TAX_INFO_SUCCESS } from '../actions/taxInfoAction';
import { LOGOUT } from '../../components/home/login/LoginAction';

const defaultState = {
    taxFilingPlans : {taxfilingPlans:[]},
    taxFilingPlan: {},
    isPlanSelected: false,
    isNewPlan: false

}
const taxFilingPlanInfoReducer = (state = {defaultState}, action) => {
    switch (action.type) {
        case GET_TAX_FILING_PLANS_INFO:
            return { ...state, taxFilingPlans: action.payload }
        case GET_TAX_FILING_PLAN_INFO:
            return { ...state, taxFilingPlan: action.payload,isPlanSelected:true }
        case PUT_TAX_INFO_SUCCESS:
            const {id,lastVisitedPage} = action.payload;
            if(state.taxFilingPlan) {
                let plans = [];
                if(state.taxFilingPlans && state.taxFilingPlans.taxfilingPlans) {
                    for( let plan of state.taxFilingPlans.taxfilingPlans) {
                        if(plan.id === id) {
                            plan = {...plan,lastVisitedPage:lastVisitedPage};
                            
                        }
                        plans.push(plan);
                    }
                }
                return { ...state, taxFilingPlans: {taxFilingPlans:plans},taxFilingPlan:{...state.taxFilingPlan,lastVisitedPage} }
            } else {
                return { ...state }
            }
            // let taxFilingPlans = state.taxFilingPlan
           
        case SET_SELECTED_PLAN_FALSE:
            return { ...state, isPlanSelected:false }
        case SET_NEW_PLAN_TRUE:
            return { ...defaultState, isNewPlan:true }
        case SET_NEW_PLAN_FALSE:
            return { ...state, isNewPlan:false }
        case RESET_TAX_INFO:
            return {...state, taxFilingPlan: {}, isNewPlan: false, isPlanSelected: false}
        case LOGOUT:
            return {defaultState}
        default:
            return state;
    }
};
export default taxFilingPlanInfoReducer;