import React from "react";
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow,Checkbox,} from '@mui/material';
import { TABLE_HEADER_BACKGROUND } from "../constants/colorScheme";

const DynamicTableHeader = (props) => {
    const { onSelectAllClick, numSelected, rowCount, colHeaders } = props;
//    checked={rowCount > 0 && numSelected === rowCount}

    return (
        <TableHead>
            <TableRow style={{backgroundColor: TABLE_HEADER_BACKGROUND}} >
                <TableCell padding="checkbox">
                 <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                    'aria-label': 'select all rows',
                    }}
                /> 
                <React.Fragment/>
                </TableCell>
                {colHeaders.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={'center'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={false}
                >
                    <span style={{color:'white'}}>{headCell.label}</span>
                </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

DynamicTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    colHeaders: PropTypes.array.isRequired,
};

export default DynamicTableHeader;