import { createSelector } from "reselect";

const additionalBusinessStateValue = (state) => state.additionalBusinessInfo.additionalBusinessInfo.state;
const additionalBusinessCityValue = (state) => state.additionalBusinessInfo.additionalBusinessInfo.city;
const loadingStateCity = (state) => state.additionalBusinessInfo.loadingStateCity;
const additionalBusinessInfoPayload = (state) => state.additionalBusinessInfo.additionalBusinessInfo;

export const getAdditionalBusinessStateValue = createSelector([additionalBusinessStateValue], (data) => data);
export const getAdditionalBusinessCityValue = createSelector([additionalBusinessCityValue], (data) => data);
export const getIsLoadingAdditionalBusinessStateCityValues = createSelector([loadingStateCity], (data) => data);
export const getAddtBizPayload = createSelector([additionalBusinessInfoPayload], (data) => data);