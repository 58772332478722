import { SET_PROFILE_DETAILS, SET_PROFILE_DETAILS_SUCCESS, SET_PROFILE_DETAILS_ERROR,
    FETCH_PROFILE_CITY_STATE, FETCH_PROFILE_CITY_STATE_SUCCESS,
    FETCH_PROFILE_CITY_STATE_FAILURE, GET_PROFILE_DETAILS, GET_PROFILE_DETAILS_SUCCESS,
    GET_PROFILE_DETAILS_ERROR, 
    SET_PARTIAL_PROFILEINFO_SUCCESS, SET_ADDRESS_MODAL_STATE,
    SET_USERPROFILE_ADDRESS_INFO,
    SET_ADDRESS_SELECTED_FLAG} from "./UserProfileActions";
import { LOGOUT } from '../../home/login/LoginAction';

const defaultState = {
    profile: {
        id:0,
        name: '',
        email: '',
        phone: 0,
        addressLine1: '',
        addressLine2: '',
        zip: '',
        state: "",
        city: '',
        loadingStateCity: false
    },
    isSubmitting: false,
    isPartialClientProfile: false, 
    addressSelected: false,
    modalState: false
}

const ClientProfileReducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_PROFILE_DETAILS:
            return {...state, isSubmitting: true }
        case SET_PROFILE_DETAILS_SUCCESS:
            console.log(action.payload)
            return {...state, profile: action.payload, isSubmitting: false }
        case SET_PROFILE_DETAILS_ERROR: 
            return {state: defaultState}
        case GET_PROFILE_DETAILS:
            return {...state, isSubmitting: true }
        case GET_PROFILE_DETAILS_SUCCESS:
            return {...state, profile: action.payload, isSubmitting: false }
        case GET_PROFILE_DETAILS_ERROR: 
            return {state: defaultState}    
        case FETCH_PROFILE_CITY_STATE:
            return { ...state, profile: { ...state.profile, state: '', city: '' }, loadingStateCity: true }
        case FETCH_PROFILE_CITY_STATE_SUCCESS:
            return { ...state, profile: { ...state.profile, state: action.payload.State, city: action.payload.City }, loadingStateCity: false }
        case FETCH_PROFILE_CITY_STATE_FAILURE:
            return { ...state, profile: { ...state.profile, state: '', city: '' }, loadingStateCity: false }
        case LOGOUT: 
            return defaultState;
        case SET_ADDRESS_MODAL_STATE:
            return { ...state, modalState: action.payload }
        case SET_PARTIAL_PROFILEINFO_SUCCESS:
            {return {...state,  profile: action.payload, isPartialClientProfile: true}}
        case SET_USERPROFILE_ADDRESS_INFO:
                return {
                    ...state,
                    profile: {
                    ...state.profile, 
                    addressLine1: action.payload.addressLine1,
                    addressLine2: action.payload.addressLine2,
                    city: action.payload.city, 
                    state: action.payload.state
                    },
                    isPartialClientProfile: false, 
                    addressSelected: true

                }
        case SET_ADDRESS_SELECTED_FLAG:
            return {...state, addressSelected: action.payload }
        default : 
            return state;
    }
}
export default ClientProfileReducer;