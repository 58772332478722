export const data = {
    // title: "FAQ",
    rows: [
        {
            title: "1. How can I change my mailing address? Can you change my mailing address by phone?",
            content: "Please send Dallas Central Appraisal District (DCAD or the Appraisal District) a request in writing (or email us at bppcs@dcad.org) indicating the correct mailing address for"+
                        "your property, a daytime phone number and email where you can be reached in case"+
                        "we have questions. In order to protect the interest of the property owner, it is DCAD's"+
                        "policy not to make permanent address changes over the telephone."
        },
        {
            title: "2. What is a rendition for Business Personal Property?",
            content:
                "A rendition is a legally required form that provides DCAD with the description, location,"+
                "cost and acquisition dates for business personal property that you own. DCAD uses "+
                "the information to help estimate the market value of your property for taxation "+
                "purposes.",
        },
        {
            title: "3. Who must file a rendition?",
            content: `Renditions must be filed by:

            1. Owners of tangible business personal property that is used for the production of income (see Question 4 below)
            2. Owners of tangible business personal property on which an exemption has been cancelled or denied.`,
        },
        {
            title: "4. What types of property must be rendered?",
            content: 
               "For taxation purposes, there are two basic types of property: real property (land,"+
                "buildings, and other items attached to land) and personal property (property that can "+
                "be owned and is not permanently attached to the land or building such as inventory,"+
                "furniture, fixtures, equipment and machinery). Business owners are required by State "+
                "law to render personal property that is used in a business or used to produce income."+
                "This property includes furniture and fixtures, equipment, machinery, computers,"+
                "inventory held for sale or rental, raw materials, finished goods, and work in process."+
                "You are not required to render intangible personal property (property that can be "+
                "owned but does not have a physical form) such as cash, accounts receivable, goodwill,"+
                "application computer software, and other similar items. If your organization has "+
                "previously qualified for an exemption that applies to personal property, for example,"+
                "a religious or charitable organization exemption, you are not required to render the "+
                "exempt property.",
        },
        {
            title: "5. Which forms will I need to file?",
            content: 
           "Depending on the type of property you are rendering, DCAD provides several types of "+
            "renditions. DCAD mails the appropriate forms to all existing businesses each year."+
            "The forms are also available on the Forms page on this website. For many business "+
            "types, you will be able to file your rendition online from the DCAD home page/Online "+
            "BPP Rendition. In order to do so, you must have received a rendition form indicating "+
            "that online filing is available. The form will provide you with additional instructions on "+
            "how to file online including a PIN number to access the online system from the DCAD "+
            "home page/Online BPP Rendition."
        },
        {
            title: "6. When must the rendition be filed?",
            content: 
           "The last day to file your rendition is April 15 annually. If you mail your rendition, it "+
            "must be postmarked by the U.S. Postal Service on or before April 15. If you hand "+
            "deliver it or use a private delivery service such as Federal Express, it must be physically "+
            "received in our office before close of business on April 15."
        },
        {
            title: "7. Where must the rendition be filed?",
            content: 
           `Renditions for property located in DCAD's jurisdiction must be filed with the Appraisal District office. See below for filing online from the DCAD home page/Online BPP Rendition. The mailing address for the general rendition is:
                          Dallas Central Appraisal District
                          Business Personal Property Division
                          PO Box 560368
                          Dallas, TX 75356
            The physical street address of the office is:
                          2949 N Stemmons Fwy
                          Dallas, TX, 75247-6195`,
        },
        {
            title: "8. Can I file electronically or e-mail a rendition?",
            content: 
           "DCAD can accept online renditions for certain business types. Eligible businesses will "+
            "receive a rendition form from DCAD with instructions on how to file online including a "+
            "PIN number to access the online system from the DCAD home page/Online BPP "+
            "Rendition. The online rendition filing does not apply to leasing companies and other "+
            "specialty property types at this time."
        },
        {
            title: "9. Can I file electronically or e-mail a rendition?",
            content: 
           "Your rendition will be analyzed, along with other information we collect on similar "+
            "businesses, to develop an estimate of value for your property."
        },
        {
            title: "10. Is my rendition information confidential?",
            content: 
           "Yes. Information contained in a rendition cannot be disclosed to third parties except in "+
            "very limited circumstances. In addition, the Texas Property Tax Code specifically "+
            "provides that any estimate of value you provide is not admissible in proceedings other "+
            "than a protest to the Appraisal Review Board (ARB) or court proceedings related to "+
            "penalties for failure to render. The final value we place on your property is public "+
            "information, but your rendition is not."
        },
        {
            title: "11. How do I fill out the general and online DCAD rendition form?",
            content: 
           `The following information applies to the preprinted form the DCAD mails out and the form that can be downloaded from our website as well as the online rendition:

            • Mailing address and physical location of the property
                The top of the preprinted form lists your company's name, DBA, and mailing address. It also gives the physical address of the property in the upper right hand corner. If any of this information is incorrect or if you have a blank form, correct or provide the requested information. The physical address applies to the January 1 location of your business. If you moved after January 1, the physical address will not change until the following year. However, you should be certain that we have your current mailing address at all times.

            • Owner signature, phone number, and notary
                In order for a rendition to be valid, it must be signed. We will return unsigned renditions for a signature. A rendition signature only needs to be notarized if the individual signing the form is the authorized agent, and not the owner or officer of the company. Please provide a phone number in case we have questions about information provided on the form.

            • Business closed, sold, or moved
                This status information about your business is very important, since it may change who is responsible for the taxes or which tax entities will be levying tax on the property. Please make sure to provide all of the information and the dates on which any of the transactions occurred.

            • General Information
                This section gives the Appraisal District information about your business to help us value your property. In order for the Appraisal District to verify the value of your personal property, we still request that you file your rendition form with cost information and the years your assets were acquired. By receiving the requested information, we can more properly value your property, even if you believe the value to be less than $20,000. Although you may use federal depreciation schedules for developing an opinion of value for your property if you have 50 employees or less, we recommend that you file cost and year of acquisition for your assets. The depreciation tables for federal income tax purposes often understate the market value of the property, and the Appraisal District may reject the rendition information you provide.

            • Inventory and Supplies
                This section is for reporting goods that you have for sale and supplies. The basis for reporting the cost of your inventory needs to be checked on the form. If your inventory is maintained using the LIFO accounting method, you must provide your LIFO reserve as a separate line item. Make certain to include raw materials and supplies on their respective lines, as these are taxable items. If you are a manufacturing business, remember to include the cost of goods in process, all applicable burden and overhead costs, including labor. Supplies are typically items that are expensed in your business throughout the year. You will need to provide the amount of supplies that were on hand as of January 1.

            • Fixed Assets or Use Items
                In order to properly estimate the value of fixed assets belonging to your business, we need the original cost and the year the assets were acquired, along with a general description. Original cost refers to the amount you paid to acquire the assets. Your cost would include transportation and any other necessary expenses incurred in acquiring the property. If you purchased a used asset, list the amount you paid and the year that you purchased the asset. If you use our rendition form, it has general categories for your assets that will help us estimate the correct value. There are seven columns on our rendition form that will assist you in organizing your business assets. Each column allows you to group the total costs of similar assets by year. We, in turn, apply appropriate depreciation to these costs based on the asset type and in some cases the type of business. Our cost index and depreciation schedule with typical lives for various asset types can be found on the Forms page of our website. Be sure to include expensed items as well as items that have depreciated to zero. All of a company's fixed assets are taxable, even though they may not appear on your depreciation tables. If you have a non-typical asset type, group them in the "Other" column, but make certain to describe the type of asset you have entered in the column. As an example, you may have electronic test equipment or hand tools that would typically receive a shorter year life than what is used on assets placed in the Machinery Equipment column. You would need to group those assets by year in the "Other" column and then note "electronic test equipment" or "hand tools" in the line just below the column.

            • Leased, Loaned, or Rented Personal Property
                Equipment that has been leased, loaned, or rented is taxable. The owner of the property is responsible for paying the taxes; however, as the lessee, you may be contractually obligated to reimburse the owner. In the space provided or on an additional sheet, list the name, address, and phone number of the owner. Give a brief description of the property, the annual rent, and if known, the selling price of the leased items. If you are leasing equipment to others, you would use the Leased Equipment/ Multi Location rendition, which is also available on our website.

            • Consigned Goods
                If you hold consigned goods, you must provide the Appraisal District with the name and address of the consignee. It would also be helpful to give a brief description, amount and cost of the goods. The section also provides an area for information describing property you own that is consigned to others. You will need to provide us with the name of the consignee, the location of the property, description of the merchandise, and the value of the consignment.

            • Opinion of Value
                The bottom of the rendition form provides a location to enter the net book value by category (inventory and fixed assets), your company's assets, or an opinion of value. We will review this information and you may be required to provide the Dallas Central Appraisal District with an explanation and documentation to support your opinion. `,
        },
        {
            title: "12. How do I determine original cost?",
            content: 
           `To determine original cost, refer to your accounting records, such as original journal entries and account ledgers. Use original purchase documents, such as invoices or purchase orders to determine the original cost of the asset. Add all costs attributed to getting the asset functioning, such as freight and set-up cost.`,
        },
        {
            title: "13. What happens if I do not file a rendition, or file it late?",
            content: 
           `If you do not file a rendition, the appraised value of your property will be based on an appraiser's estimate using comparable business types. In addition, if you fail to file your rendition before the deadline or you do not file it at all, a penalty equal to 10% of the amount of taxes ultimately imposed on the property will be levied against you. There is also a 50% penalty if a court finds you engaged in fraud or other actions with the intent to evade taxes.`,
        },
        {
            title: "14. If I cannot file the rendition on time, what should I do?",
            content: 
           `The law provides for an extension of time to file a rendition. In order to receive the extension, you must submit the request to the Dallas Central Appraisal District in writing before the April 15 rendition filing deadline. With the receipt of a timely extension request, the rendition filing deadline will be extended to May 15.`,
        },
        {
            title: "15. What do I do if the Chief Appraiser requests an explanatory statement from me?",
            content: 
           `If you provide a good faith estimate of market value instead of original cost and acquisition date for any items, the Chief Appraiser may request an explanatory statement from you. The Chief Appraiser must make the request in writing, and you must provide the statement within 20 days of the date you receive the request. The explanatory statement must set out a detailed explanation of the basis for the estimate(s) of market value given in your rendition. The statement must include adequate information to identify the property. It must describe the physical and economic characteristics of the property that are relevant to its market value. It must also give the source(s) of information used in valuing the property and explain the basis for the value estimate.`,
        },
        {
            title: `16. What if the Appraisal District values my property at a higher amount than what I
            render?`,
            content: 
           "You will receive a Notice of Appraised Value in mid-May or early June. If you disagree "+
            "with the value placed on your property by the Appraisal District, you may wish to file "+
            "a protest with the Appraisal Review Board. The written protest must be filed by the "+
            "deadline date indicated on the Notice. Once a timely protest is received, you will be "+
            "scheduled for a hearing before the Appraisal Review Board. At your Appraisal Review "+
            "Board hearing, you will be asked to provide documentation."
        },
        {
            title: `17. Can I fax my rendition?`,
            content: 
           "No. In order to be considered timely filed, your rendition must either be physically "+
            "delivered to the district prior to the deadline or mailed to the Appraisal District and "+
            "postmarked prior to the deadline, or if available, submit your rendition online utilizing "+
            "DCAD's online rendition processing system prior to the April 15 rendition deadline date."
        },
        {
            title: `18. If I cannot file by the extension deadline, what should I do?`,
            content: 
           "You should file your rendition as soon as you can. If possible, we will process your "+
            "rendition prior to mailing notices."
        },
        {
            title: `19. What is a Freeport Exemption?`,
            content: 
           "The Freeport exemption applies to goods, wares, ores and merchandise other than oil, "+
            "gas and petroleum products (defined as liquid and gaseous materials immediately "+
            "derived from refining petroleum or natural gas) and to aircraft or repair parts used by "+
            "a certificated air carrier. The Freeport goods qualify if they leave Texas within 175 "+
            "days from the date they are brought into or acquired in the state. The property owner "+
            "or agent must file the application no later than April 30th annually. Late applications "+
            "can be accepted up until June 15th, but there is a 10% penalty against the exempt "+
            "portion of the inventory. DCAD may request documentation from the property owner "+
            "or agent to support the exemption claim. The exemption application must be filed "+
            "annually. A listing of all taxing entities that grant the Freeport exemption and the "+
            "associated application can be found under “Forms” on this website."
        },
        {
            title: `20. What is a Goods in Transit Exemption?`,
            content: 
           "The Goods in Transit exemption covers inventory acquired inside or outside the state, "+
            "stored under contract of bailment at a facility in which the owner of the goods has no "+
            "direct or indirect ownership of the facility, detained for storing purposes by the person "+
            "who acquired or imported the property, and then shipped to another location in or out "+
            "of this state within 175 days. The goods do not include oil, gas or petroleum products "+
            "or special inventories such as motor vehicles or boats in a dealer’s retail inventory. "+
            "The property owner or agent must file the application no later than April 30th annually. "+
            "DCAD may request documentation from the property owner or agent to support the "+
            "exemption claim. The exemption application must be filed annually. Taxing units may "+
            "elect to tax the goods. The DCAD website lists all taxing entities that have opted to "+
            "grant the exemption. The exemption application must be filed annually. A listing of all "+
            "taxing entities that grant the Goods in Transit exemption and the associated "+
            "application can be found under “Forms” on this website."
        },
        {
            title: `21. What is a Pollution Control Exemption?`,
            content: 
           "Certain property, both real and personal, may receive an exemption if the property is "+
           " used wholly or partially as a control for air, water, or land pollution. This exemption"+
            "does not extend to the manufacturer of the pollution control equipment. The exemption "+
            "is based on a letter from the executive director of the Texas Commission on "+
            "Environmental Quality. The letter will state whether the equipment is considered to be "+
            "wholly are partially to control pollution. A Pollution Control exemption application must "+
            "be filed no later than April 30th, but does not have to be filed annually. The Pollution "+
            "Control exemption application can be found under the “Forms” on this website.",
        },
        {
            title: `22. What is a Motor Vehicle Used for Production of Income and Personal Use Exemption?`,
            content: 
           "An individual is entitled to an exemption of taxation of one motor vehicle owned by "+
            "the individual that is used in the course of the individual’s occupation or profession "+
            "and is also used for personal activities of the owner that do not involve the production "+
            "of income. This does not apply to a motor vehicle used to transport passengers for "+
            "hire. A motor vehicle means a passenger car or light truck. This exemption application "+
            "must be filed by April 30th, but does not have to be filed annually. The Motor Vehicle "+
            "Used for Production of Income and Personal Use exemption application can be found "+
            "under “Forms” on this website.",
        },
        {
            title: `23. What is Interstate Allocation?`,
            content: 
            "If personal property that is taxable by a taxing unit is used continually outside of "+
             "Texas, whether regularly or irregularly, the appraisal office shall allocate to Texas the "+
             "portion of the total market value of the property that fairly reflects its use in Texas. "+
             "For DCAD the interstate allocation primarily applies to aircraft, motor vehicles, trucks, "+
             "trailers, and railroad rolling stock not owned by a railroad. A property owner or agent "+
             "must file a Motor Vehicle/Equipment or Aircraft Interstate Allocation Rendition and "+
             "Application for Interstate Allocation of Value with the appraisal district where the "+
             "property has taxable situs. The Application for Interstate Allocation of Value must be "+
             "filed no later than April 30th annually. A new account’s allocation application deadline "+
             "is extended to 30 days after the receipt of a notice of appraised value. Late applications "+
             "can be accepted up until the day before the roll is certified, but there is a 10% penalty "+
             "against the portion of the allocated property that is outside of Texas. The Interstate "+
             "Allocation renditions and applications can be found under “Forms” on this website.",
        },
    ],
};