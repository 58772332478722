import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const postInventoryInfo = (payload) => {
    return svcRequest.post(config.inventory, payload, {
      timeout: 30000
    }
  );
}

export const getInventoryInfo = (locationId, taxYear, taxFilingPlanId) => {
  let reqParams= {
    locationId: locationId, 
    taxYear: taxYear, 
    taxFilingPlanId: taxFilingPlanId
  }
  return svcRequest.get(config.inventory, {params: reqParams},{
    timeout: 30000
  });
}

export const updateInventory = (inventoryId, payload) => {
  return svcRequest.patch(config.inventory+inventoryId, payload, {
    timeout: 30000
  });
}