import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";

export const postRendition = (payload) => {
  return svcRequest.post(config.rendition, payload, { responseType: 'blob' }, {
      timeout: 300000
    }
  );
}

export const getAllAssets = (locationId, taxFilingPlanId) => {
  return svcRequest.get(config.asset, {params:{locationInfoId: locationId, taxFilingPlanId: taxFilingPlanId}},  {timeout: 30000});
}

export const getAllLeasedAssets = (locationId, taxFilingPlanId) => {
  return  svcRequest.get(config.leasedAssets, {params: {locationInfoId: locationId, taxFilingPlanId: taxFilingPlanId}}, {timeout: 30000});
}

export const getAllInventory = (locationInfoId, taxFilingPlanId, taxYear) => {
  return svcRequest.get(config.inventory, {params:{locationId: locationInfoId, taxFilingPlanId: taxFilingPlanId, taxYear: taxYear}}, {timeout: 30000});
}

export const getAllSupplies = (locationInfoId, taxFilingPlanId, taxYear) => {
  return svcRequest.get(config.supplies, {params: {locationInfoId: locationInfoId, taxFilingPlanId: taxFilingPlanId, taxYear: taxYear}}, {timeout: 30000});
}