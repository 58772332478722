import {REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE} from './RegisterActions';

const defaultState ={
    payload: {
        name: '', email: '', pwd: '', cnfPwd: '', agentCheck: ''
    },
    isSending: false
}

const RegisterReducer = (state = defaultState, action) => {
    switch(action.type){
        case REGISTER: 
            return {...state, payload: action.payload, isSending: true};
        case REGISTER_SUCCESS:
        case REGISTER_FAILURE:
            return { payload: defaultState }
        default : 
            return state;
    }
}
export default RegisterReducer;