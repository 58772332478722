import { SET_CLIENT_DETAILS, SET_PROFILE_STATUS, SET_MODAL_STATE, SET_CURRENT_PANE, 
    SET_LOCATIONINFO_STATUS, SET_BUSINESSINFO_STATUS, LOAD_BUSINESSES_OF_AN_YEAR, 
    LOAD_BUSINESSES_SUCCESS, CURRENT_BUSINESS_STATE, LOAD_LOCATIONS_SUCCESS, 
    CURRENT_LOCATION_STATE,
    SET_APP_MODE, SET_LEASED_ASSETS_STATUS,
    FETCH_STATE_VALUES, FETCH_COUNTY_VALUES, FETCH_CITY_VALUES, SET_BULK_MODAL_STATE, SET_SUPPLIESINFO_STATUS, SET_INVENTORYINFO_STATUS, SET_ASSETINFO_STATUS, FETCH_BANNER_INFO, CURRENT_ADDITIONAL_BUSINESS_STATE} from "./AppClientActions";
import { LOGOUT } from '../home/login/LoginAction';

const defaultState = {
    clientDetails: {
        firstName: 'Bindu',
        lastName: 'vadrevu',
        email: 'bindumadhavi309@gmail.com',
        userType: 'CLIENT'
    },
    isProfileComplete: false,
    modalState: false,
    currentPaneStep: '',
    isLocationComplete: false,
    isBusinessComplete: false,
    taxYr: '',
    businesses: [],
    currBusiness: '',
    locations: [],
    currLocation: '',
    bulkAssetsModalstate: false,
    appMode: false,
    currAdditionalBusiness : ''
}

const AppClientReducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_CLIENT_DETAILS:
            return {...state, clientDetails: action.payload }
        case SET_PROFILE_STATUS:
            return {...state, isProfileComplete: action.payload }
        case SET_MODAL_STATE:
            return {...state, modalState: action.payload }
        case SET_CURRENT_PANE:
            return { ...state, currentPaneStep: action.payload }
        case SET_LOCATIONINFO_STATUS:
            return { ...state, isLocationComplete: action.payload }
        case SET_BUSINESSINFO_STATUS:
            return { ...state, isBusinessComplete: action.payload }
        case LOAD_BUSINESSES_OF_AN_YEAR:
            return { ...state, taxYr: action.payload}
        case LOAD_BUSINESSES_SUCCESS:
            return { ...state, businesses: action.payload}
        case CURRENT_BUSINESS_STATE:
            return { ...state, currBusiness: action.payload}
        case LOAD_LOCATIONS_SUCCESS:
            return { ...state, locations: action.payload}
        case CURRENT_LOCATION_STATE:
            return { ...state, currLocation: action.payload}
        case SET_APP_MODE:
            return {...state, appMode: action.payload}
        case FETCH_STATE_VALUES:
            return { ...state, stateValues: action.payload }
        case FETCH_COUNTY_VALUES:
            return { ...state, countyValues: action.payload }
        case FETCH_CITY_VALUES:
            return { ...state, cityValues: action.payload }
        case LOGOUT:
            return defaultState
        case SET_BULK_MODAL_STATE:
            return {...state, bulkAssetsModalstate: action.payload }
        case SET_SUPPLIESINFO_STATUS:
            return { ...state, suppliesStatus: action.payload }
        case SET_INVENTORYINFO_STATUS:
            return { ...state, inventoryStatus: action.payload }
        case SET_ASSETINFO_STATUS:
            return { ...state, assetStatus: action.payload }
        case SET_LEASED_ASSETS_STATUS:
            return { ...state, leasedAssetStatus: action.payload }
        case FETCH_BANNER_INFO:
            return { ...state, bannerContent: action.payload }
        case CURRENT_ADDITIONAL_BUSINESS_STATE:
            return { ...state, currAdditionalBusiness: action.payload }
        default : 
            return state;
    }
}
export default AppClientReducer;