import { svcRequest } from "../util/requestHelper";
import config from "../config/context-root-configs";


export const getBusinessRelatedToUser = (isClient, id) => {
    if (isClient) {
        return getDirectClientBusinessInfoFromUserId(id);
    }
    else {
        return getDirectAgentBusinessInfoFromUserId(id)
    }
}

export const getDirectClientBusinessInfoFromUserId = (userId) => {
    return svcRequest.get(config.clientBusinessInfo, { params: { directClientInfoId: userId } }, {
        timeout: 30000
    })
}

export const getDirectAgentBusinessInfoFromUserId = (userId) => {
    return svcRequest.get(config.agentBusinessInfo, { params: { agentInfoId: userId } }, {
        timeout: 30000
    })
}

export const getBusinessInfoFromBusinessId = (isClient, businessId) => {
    if (businessId) {
        if (isClient) {
            return svcRequest.get(config.clientBusinessInfo + businessId, {
                timeout: 30000
            })
        } else {
            return svcRequest.get(config.agentBusinessInfo + businessId, {
                timeout: 30000
            })
        }
    }


}
